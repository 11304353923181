import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { animateNumber } from '../../assets/js/counter';


// all img
import Right_Poster from '../../assets/img/Navoday/right-side.png'
import Bulb_Icon from '../../assets/img/bulb_icon.png'
import Static_icon_1 from '../../assets/img/Static_icon_1.png'
import Static_icon_2 from '../../assets/img/Static_icon_2.png'
import Static_icon_3 from '../../assets/img/Static_icon_3.png'
import Arrow_Btn from '../../assets/img/arrow_btn.png'
import Arrow_icon from '../../assets/img/Arrow_icon.png'
import Test from '../../assets/img/test.png'

function Navodayhome() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    animateNumber('num1', 0, 3500, 3000);
    animateNumber('num2', 0, 40000, 3000);
    animateNumber('num3', 0, 17000, 3000);
    animateNumber('num4', 0, 1000000, 3000);
  }, []);

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 50;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  const spanStyle = {
    color: '#fff',
    background: 'linear-gradient(80deg, #7437ec 0, #22c1e1 100%)',
    marginTop: '5%',
    borderRadius: '8px',
    fontSize: '1.3rem',
    fontWeight: '510',
  };

  const [teacher, setTeacher] = useState([]);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/navoday/getteacher`);
      setTeacher(res.data);
      setIsLoading(false);
    };
    fetchData();
  }, [render]);

  useEffect(() => {
    document.title = "નવોદય પરીક્ષા મિશન - Edutor App";
  }, []);


  return (
    <>
      <Header />
      <div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 hero_img">
              <img src={Right_Poster} alt="Right_Poster"/>
            </div>
            <div className="col-lg-6">
              <div className="user_hero">
                <div className="user_sub_hero mx-5">
                  <h1 className='fw-bold' style={{ color: '#2577e0' }}>નવોદય પરીક્ષા મિશન</h1>
                  <p className='All_paragraph'>નવોદયની તૈયારી માટે ખાસ રાજ્યનાં શ્રેષ્ઠ શિક્ષકો દ્વારા તૈયાર 600 થી વધુ પ્રેક્ટિસ ક્વિઝ</p>
                  <p className='All_paragraph'>વોટ્સએપનાં ગ્રુપમાં દરરોજ ટોપિક પ્રમાણે 10 પ્રેક્ટિસ ક્વિઝ અને ઉપયોગી મટીરિયલ/વિડિયો મોકલવામાં આવશે</p>
                  <a className="btn btn-primary user_btn_clr" href="https://chat.whatsapp.com/HFwMNQaAa8s9NEJZ8pB2dB">તમારાં જિલ્લાનાં ગ્રુપમાં હમણાં જ જોડાઓ</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-light my-5 All_achive">
          <div className="row All_heading">
            <div className="col-md-12 text-center">
              <h1 className="mb-0 fw-bold" style={{ color: '#2577e0' }}>નવોદય પરીક્ષા મિશન 2023 ની ઉજળી સફળતા</h1>
            </div>
          </div>
          <div className="row my-4 All_achive_767">
            <div className="col-sm achive d-flex flex-row justify-content-center reveal fade-bottom">
              <div className="text-center">
                <img src={Bulb_Icon} alt="Bulb_Icon" />
              </div>
              <div className="text-left mx-2">
                <h3 className="num mb-0 mt-3 fw-bold" id="num1">3,500</h3>
                <p className='mb-0'>પ્રેક્ટિસ ક્વિઝો બની</p>
              </div>
            </div>

            <div className="col-sm achive d-flex flex-row justify-content-center reveal fade-bottom">
              <div className="text-center">
                <img src={Bulb_Icon} alt="Bulb_Icon" />
              </div>
              <div className="text-left mx-2">
                <h3 className="num mb-0 mt-3 fw-bold" id="num2">40,000</h3>
                <p className='mb-0'>વિદ્યાર્થીઓ જોડાયા</p>
              </div>
            </div>

            <div className="col-sm achive d-flex flex-row justify-content-center reveal fade-bottom">
              <div className="text-center">
                <img src={Bulb_Icon} alt="Bulb_Icon" />
              </div>
              <div className="text-left mx-2">
                <h3 className="num mb-0 mt-3 fw-bold" id="num3">17,000</h3>
                <p className='mb-0'>પ્રેક્ટિસ પ્રશ્નો બન્યા</p>
              </div>
            </div>

            <div className="col-sm achive d-flex flex-row justify-content-center reveal fade-bottom">
              <div className="text-center">
                <img src={Bulb_Icon} alt="Bulb_Icon" />
              </div>
              <div className="text-left mx-2">
                <h3 className="num mb-0 mt-3 fw-bold" id="num4">1,000,000</h3>
                <p className='mb-0'>વખત ક્વિઝ અપાઈ</p>
              </div>
            </div>
          </div>
          <div>
            <div className="justify-content-center All_sub_achive">
              <div className="reveal fade-bottom">
                <span className="d-inline-block pt-3 pb-2 px-4" style={spanStyle}>
                  1050 વિદ્યાર્થીઓ પાસ થયા
                </span>
              </div>
              <div className="reveal fade-bottom">
                <span className="d-inline-block pt-3 pb-2 px-4" style={spanStyle}>
                  523 વિદ્યાર્થીઓ મેરીટમાં આવ્યા
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5 sub_container_teacher">
          <div>
            <div className="main_title">
              <h1 className='fw-bold'>શિક્ષક દીઠ ક્વિઝ</h1>
            </div>
            <>
              {isLoading ? (
                <center>
                <div class="spinner-border m-5 text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                </center>
              ) : (
            teacher.map((teacher, index) => (
            <Link to={`/navodaya/teacher/${teacher._id}` }className="slide-top">
              <div className="bg-light px-0 teacher_profile  reveal fade-bottom">
                <div className="teacher_img static_img">
                  <img src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Navoday/TeacherProfile/${teacher.teacherimg}`} alt="Teacher-Profile" />
                </div>
                <div className="teacher_detail">
                  <h3 className='mb-2 fw-bold'>{teacher.teachername}<span> . </span><em className='fw-light'> {teacher.addquiz.length} &nbsp;ક્વિઝ</em></h3>
                  <p className="All_paragraph mb-2">{teacher.schoolname}</p>
                  <p className="All_paragraph mb-2">ગણિત</p>
                </div>
                <div className="arrow_img">
                  <img src={Arrow_icon} alt="Arrow_icon" />
                </div>
              </div>
            </Link>
             ))
             )}
           </>
          </div>
          <div className="d-flex justify-content-evenly Show_more_btn">
            <a href="#" className="btn btn-primary user_btn_clr">
              વધું જુઓ <img src={Arrow_Btn} alt="Arrow_Btn" />
            </a>
          </div>
        </div>

        <div className="container my-5 sub_container_teacher">
          <div className="teacher-profile">
            <div className="main_title">
              <h1 className="fw-bold">કેવી રીતે તૈયારી કરવી ?</h1>
            </div>
            <div className="bg-light pt-3 pb-3 px-0 static_profile reveal fade-bottom">
              <div className="teacher_img static_img">
                <img src={Static_icon_1} alt="Static_icon_1" />
              </div>
              <div className="static_detail">
                <h3>વિદ્યાર્થી એકાઉન્ટ કેવી રીતે બનાવવું ?</h3>
                <Link className="btn btn-primary user_btn_clr" to="https://youtube.com/shorts/dG7Wcc2fhkY?feature=share">
                  હમણાં જ જુઓ
                </Link>
              </div>
            </div>

            <div className="bg-light pt-3 pb-3 px-0 static_profile reveal fade-bottom">
              <div className="teacher_img static_img">
                <img src={Static_icon_2} alt="Static_icon_2" />
              </div>
              <div className="static_detail">
                <h3>ક્વિઝ કેવી રીતે આપવી ?</h3>
                <Link className="btn btn-primary user_btn_clr" to="https://youtube.com/shorts/mBwIH4L5sFY?feature=share">
                  હમણાં જ જુઓ
                </Link>
              </div>
            </div>

            <div className="bg-light pt-3 pb-3 px-0 static_profile reveal fade-bottom">
              <div className="teacher_img static_img">
                <img src={Static_icon_3} alt="Static_icon_3" />
              </div>
              <div className="static_detail">
                <h3>વધું પ્રેક્ટિસ કેવી રીતે કરવી ?</h3>
                <Link className="btn btn-primary user_btn_clr" to="https://youtu.be/Acannv-EXPY">
                  હમણાં જ જુઓ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Navodayhome