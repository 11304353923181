// counter.js
export function animateNumber(id, start, end, duration) {
  var range = end - start;
  var current = start;
  var increment = end > start ? 70 : -1;
  var stepTime;
  if (end > 100000) {
    stepTime = Math.abs(Math.floor(duration / (range / 20)));
    increment = increment * 20;
  } else {
    stepTime = Math.abs(Math.floor(duration / range));
  }
  var element = document.getElementById(id);
  var timer = setInterval(function () {
    current += increment;
    if (current > end) {
      current = end;
    }
    element.innerHTML = current.toLocaleString();
    if (current == end) {
      clearInterval(timer);
    }
  }, stepTime);
}
