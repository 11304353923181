import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";

const ShowQuiz = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { id } = useParams("");

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  //Add Quiz NMMS
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState("");
  const [tittle, setTittle] = useState("");
  const [link, setLink] = useState("");
  const SubmitTeamInfo = async (e) => {
    e.preventDefault();

    const data = {
      subject,
      year,
      tittle,
      link,
    };

    try {
      await axios.patch(`${API_BASE_URL}/api/showquiz/${id}`, data);
      GetTeamInfo();
      alert("New Quiz Added!");
      setSubject("");
      setYear("");
      setTittle("");
      setLink("");
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error Added team info:", error.message);
    }
  };

  //Display Get Quiz
  const [teaminfo, setarr] = useState([]);
  const [getuserdata, setuserdata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getquiz/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setarr(data.addquiz);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //Delete Team info
  const deleteInfo = async (nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      const res1 = await fetch(
        `${API_BASE_URL}/api/deletequiz/${nid}/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data2 = await res1.json();
      if (data2) {
        GetTeamInfo();
        window.alert("Record deleted successfully!");
      } else {
        window.alert("Error deleting record");
      }
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Quiz</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">NMMS</li>
              <li className="breadcrumb-item active">Add Quiz</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form
                method="POST"
                onSubmit={SubmitTeamInfo}
                className="row g-3"
                encType="multipart/form-data"
              >
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Enter Subject:
                  </label>
                  <select
                    className="form-control mt-1"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option selected>Select Subject</option>
                    <option value="ગણિત">ગણિત</option>
                    <option value="MAT">માનસિક ક્ષમતા કસોટી</option>
                    <option value="વિજ્ઞાન">વિજ્ઞાન</option>
                    <option value="સામાજીક વિજ્ઞાન">સામાજીક વિજ્ઞાન</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Enter Year:</label>
                  <select
                    className="form-control mt-1"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option selected>Select year</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Quiz Tittle:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={tittle}
                    onChange={(e) => setTittle(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Quiz Link:
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    name="insert"
                  >
                    Add Quiz
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
        <b>
          <h4>Lasted Quiz List</h4>
        </b>
        <div className="scrolltable" style={{ overflow: "auto" }}>
        <table className="table table-bordered" id="table-scroll">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Year</th>
              <th scope="col">Subject</th>
              <th scope="col">Quiz Tittle</th>
              <th scope="col">Quiz Link</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {teaminfo
            .reverse()
            .slice(0)
            .map((ele, i) => {
              return (
                <tbody>
                  <tr>
                    <th>{i + 1}</th>
                    <th>{ele.year}</th>
                    <th>{ele.subject}</th>
                    <th>{ele.tittle}</th>
                    <td>{ele.link}</td>
                    <td>
                      <Link to={`/nmms/editquiz/${ele._id}/${id}`}>
                      <button className="btn btn-success" ><i class="bi bi-pencil-square"></i></button>
                      </Link> &nbsp;
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteInfo(ele._id)}
                      >
                        <i class="bi bi-trash ml-5"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
        </table>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default ShowQuiz;
