import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const EduMitraContent = () => {
  const navigate = useNavigate();
  const [selectedStandard, setselectedStandard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState();
  const [subjects, setsubjects] = useState([]);
  const [ChaptersName, setChaptersName] = useState([]);
  const [selectedChapter, setselectedChapter] = useState("");
  const [selectedEduMitra, setselectEduMitra] = useState("");
  const [ChaptersData, setChaptersData] = useState([]);
  const [Content, setContent] = useState("");
  const [EduMitra, setEduMitra] = useState([]);
  const [EduMitraContent, setEduMitraContent] = useState([]);
  const [LoadAgain, setLoadAgain] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    FetchEduMitraContent();
  }, [LoadAgain]);

  const handleStandard = async (value) => {
    await FetchSubjects(value);
    setselectedStandard(value);
  };

  const handleChapter = async (value) => {
    await FetchEduMitra(value, selectedType);
    setselectedChapter(value);
  };

  const handleSubject = async (subjectID) => {
    setSelectedSubject(subjectID);
    if (selectedType === "2") {
      await FetchChaptersData(subjectID);
    }

    if (selectedType === "1") {
      await FetchEduMitra(subjectID, selectedType);
    }
  };

  // Subject List
  const FetchSubjects = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
        {
          params: {
            standard_id: value,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Fetch Chapter Data
  const FetchChaptersData = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/chapter?subject_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setChaptersData(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  const FetchEduMitraContent = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/edu-mitra-content`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setEduMitraContent(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  // Fetch Topic Data
  const FetchEduMitra = async (value, type) => {
    let url;
    if (type === "1") {
      url = `${REACT_APP_NODE_API_BASE_URL}/edu-mitra?subject_id=${value}&type=1`;
    } else if (type === "2") {
      url = `${REACT_APP_NODE_API_BASE_URL}/edu-mitra?chapter_id=${value}&type=1`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        setEduMitra(response.data);
      }
    } catch (error) {
      console.error("Error fetching EduMitra:", error);
      setEduMitra([]);
    }
  };

  const convertTypeToNumber = (type) => {
    switch (type) {
      case "t":
        return 1;
      case "e":
        return 2;
      case "m":
        return 3;
      case "v":
        return 4;
      default:
        return -1; // Invalid type
    }
  };

  const AddTopic = async () => {
    setLoadAgain(false);
    if (
      !selectedStandard?.length ||
      !selectedSubject?.length ||
      !Content ||
      !selectedType?.length ||
      !selectedEduMitra?.length
    ) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    const regex = /content_id\/([temv])=(.*?)$/;
    const match = Content.match(regex);

    if (match && match.length > 2) {
      const type = match[1];
      const base64String = match[2];
      const decodedString = atob(base64String);
      const id = decodedString;

      const numericType = convertTypeToNumber(type); // Fix: Uncommented this line

      if (numericType !== -1) {
        try {
          const newTopicData = {
            edumitra_id: parseInt(selectedEduMitra),
            content_type: parseInt(numericType),
            content_id: parseInt(id),
          };

          const addTopicResponse = await axios.post(
            `${REACT_APP_NODE_API_BASE_URL}/admin/add/edu-mitra-content`,
            newTopicData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (addTopicResponse.data) {
            toast.success(addTopicResponse.data.message, {
              position: "top-center",
            });
            setLoadAgain(true);
            setContent("");
            // setselectedSubject("");
            // setselectedStandard("");
            // setChaptersName("");
          }
        } catch (error) {
          console.error("Error adding topic:", error);
        }
      }
    } else {
      toast.error("Invalid URL", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Upload EduMitra Content</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Upload EduMitra Content</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Standard:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedStandard}
                    onChange={(e) => handleStandard(e.target.value)}
                  >
                    <option selected value="">
                      Select Standard
                    </option>
                    <option value="1">ધોરણ 12</option>
                    <option value="2">ધોરણ 11</option>
                    <option value="3">ધોરણ 10</option>
                    <option value="4">ધોરણ 9</option>
                    <option value="5">ધોરણ 8</option>
                    <option value="6">ધોરણ 7</option>
                    <option value="7">ધોરણ 6</option>
                    <option value="8">ધોરણ 5</option>
                    <option value="9">ધોરણ 4</option>
                    <option value="10">ધોરણ 3</option>
                    <option value="11">ધોરણ 2</option>
                    <option value="12">ધોરણ 1</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Type:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option selected value="">
                      Select Type
                    </option>
                    <option value="1">વિષય મુજબ</option>
                    <option value="2">પ્રકરણ મુજબનું</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedSubject}
                    onChange={(e) => handleSubject(e.target.value)}
                  >
                    <option selected>Select subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedType === "2" && (
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Chapter:</label>

                    <select
                      className="form-control mt-1"
                      value={selectedChapter}
                      onChange={(e) => handleChapter(e.target.value)}
                    >
                      <option selected>Select Chapter</option>
                      {ChaptersData.map((chapter, index) => (
                        <option key={index} value={chapter.chapter_id}>
                          {chapter.chapter_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    EduMitra Type:
                  </label>

                  <select
                    className="form-control mt-1"
                    value={selectedEduMitra}
                    onChange={(e) => setselectEduMitra(e.target.value)}
                  >
                    <option selected>Select EduMitra</option>
                    {EduMitra.map((edu, index) => (
                      <option key={index} value={edu.eduMitra_id}>
                        {edu.tittle}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter EduMitra Content:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={Content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="insert"
                    onClick={AddTopic}
                  >
                    Add Content
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <b>
                <h4 className="mt-3">
                  <b>Lasted</b>
                </h4>
              </b>
              <div className="scrolltable" style={{ overflow: "auto" }}>
                <table className="table table-bordered" id="table-scroll">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Content ID</th>
                      <th scope="col">Type</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Content Tittle</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  {EduMitraContent.map((item, i) => {
                    return (
                      <tbody>
                        <tr key={item}>
                          <th>{i + 1}</th>
                          <th>edumitra_content_{item.edumitra_content_id}</th>
                          <th>{item.content_type}</th>
                          <th>{item.subject}</th>
                          <th>{item.title}</th>
                          {/* <td></td> */}
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default EduMitraContent;
