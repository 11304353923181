import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;
const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxOCIsImp0aSI6IjNkNTcyZGI1NzUyNWU0MDQ3NTU4NjcxYzhmNzhhMTIzZTE5NWVhOTlkOWFiNmEyNTdkOTM4ODA5MzA3MzRlNjY0MGViMDA4YjE3MjQyZTI0IiwiaWF0IjoxNzA3NDcxMzE5LjQxNjkzMSwibmJmIjoxNzA3NDcxMzE5LjQxNjkzNSwiZXhwIjoxNzM5MDkzNzE5LjI2NjQ1LCJzdWIiOiIxNTc0ODkiLCJzY29wZXMiOltdfQ.PTeShE1i0ieF-ItcdcDSMUsEu4dznx_ofNpQgrQN3Km5JpdImWTt0jWoN3Lar6BTQqlJcdYyWXj89BTHixmAyFGWfGbd1NT0mu_6XUShw1Q4lYdvzrEipzEf2cRt4bKYd24cTfWx8q2c2aHbFThqhW7KIoHf07Jf-3T8vtLIP3hNEGegwLSnpWIfSWSpQ_u3b2LmrNnzdWd3msOsdD3myy198ZVDC7guYKN9E2x2kOm6h9eKLycbVO4uSco3QNRim03VSJEZKTQ3re2hwFYIPaeEhQhVvylX6Ymd9Xor4bZ18flV5BUfUf52Yq2hhCbrlamyFnvyRJF8KIlCvTNIbEFI0mL4JfWfYXgveCJdEw1X6ZTfPHHXzuG-nIJOfM_SzSRdKWsd9WppCmAQMdtk3fvfUu-2oD12hbAUyChO1ABgpcuuNivON7wOE1jEHWmerrrLgldDJuJVMwH5v2FhJaqM9JgAqdu-ShEFPrNF8q87Pf1i6_yjJZK6Yjt368Y5Nqtm9VgOPp7S6_u5JZfiztMlGv90n4u0Ie9Nc_w2JqNZXXjaWsgpBDrwGIbT1jqLhDyz51qbOiyxZvJZr2-RyPA25CC6SB30H_2ePXww8ddbzDQnlSJ4a4-QL-nYISI0L_z54dLlxt2dMRL7I-MtZfmaQpo4wRnLBvibqdSV-OM";

const AddSchool = () => {
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState({
    user_id: "",
    school_id: "",
    school_name: "",
    school_village: "",
    school_pincode: "",
  });

  const location = useLocation();
  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(location.search);
    const user_id = params.get("user_id") || "";
    const school_id = params.get("school_id") || "";
    const school_name = params.get("school_name") || "";
    const school_village = params.get("school_village") || "";
    const school_pincode = params.get("school_pincode") || "";

    // Set form data
    setSchoolData({
      user_id,
      school_id,
      school_name,
      school_village,
      school_pincode,
    });
  }, [location.search]);

  const handleChange = (e) => {
    setSchoolData({ ...schoolData, [e.target.name]: e.target.value });
  };

  const addUserSchool = async () => {
    if (
      !schoolData.school_id ||
      !schoolData.school_name ||
      !schoolData.school_village ||
      !schoolData.school_pincode
    ) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }
    try {
      const newData = {
        userID: schoolData.user_id,
        school_id: schoolData.school_id,
        school_name: schoolData.school_name,
        school_village: schoolData.school_village,
        school_pincode: schoolData.school_pincode,
      };

      const addChapterResponse = await axios.post(
        `${REACT_APP_NODE_API_BASE_URL}/school`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      if (addChapterResponse.data) {
        toast.success("Your School Is Added", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
      });
      console.error("Error adding chapter:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add School</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">add school</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    UserID: (optional)
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    name="user_id"
                    value={schoolData.user_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School UDISE:
                  </label>
                  <input
                    type="tel"
                    name="school_id"
                    className="form-control"
                    value={schoolData.school_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School Name:
                  </label>
                  <input
                    type="text"
                     name="school_name"
                    className="form-control"
                    value={schoolData.school_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School Village:
                  </label>
                  <input
                    type="text"
                    name="school_village"
                    className="form-control"
                    value={schoolData.school_village}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School Pincode:
                  </label>
                  <input
                    type="tel"
                   name="school_pincode"
                    className="form-control"
                    value={schoolData.school_pincode}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="insert"
                    onClick={addUserSchool}
                  >
                    Add School
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddSchool;
