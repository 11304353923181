import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function DtmAdmin() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [team, setUsers] = useState([]);
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate("/admin/login");
    } else {
    }
  };

  useEffect(() => {
    UserdValid();
  }, []);

  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getteam`);
      setUsers(res.data);
      setLoading(false);
    };
    fetchData();
  }, [render]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this team?"
    );

    if (confirmed) {
      await axios.delete(`${API_BASE_URL}/api/deleteteam/${id}`);
      setRender((prevState) => !prevState);
      alert("Team is deleted successfully!");
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>DTM Team list</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <p>Home</p>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">DTM Team list</li>
            </ol>
          </nav>
          <Link to="/dtm/create-team-profile" className="btn btn-primary">
            Create Team profile
          </Link>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Digital Teacher Mission</h5>
            <div className="scrolltable" style={{ overflow: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Team name</th>
                    <th scope="col">All Teacher </th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                {loading ? (
                  <div class="spinner-border text-primary mt-3" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <tbody>
                    {team
                      .slice()
                      .reverse()
                      .map((team, index) => (
                        <tr key={`${index}-${team._id}`}>
                          <td>{index + 1}</td>
                          <td>{team.teamname}</td>
                          <td>
                            <Link to={`/dtm/team-info/${team._id}`}>
                              <button className="btn btn-primary">
                                Team Info
                              </button>
                            </Link>
                          </td>
                          <td>
                            <Link to={`/dtm/add-content/${team._id}`}>
                              <button className="btn btn-primary">
                                Add Content
                              </button>
                            </Link>
                          </td>
                          <td>
                            <Link to={`/dtm/edit-team-profile/${team._id}`}>
                              <button className="btn btn-success">
                                <i class="bi bi-pencil-square"></i>
                              </button>
                            </Link>
                            &nbsp;
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(team._id)}
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default DtmAdmin;
