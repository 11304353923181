import React from "react";

import fb_icon from "../assets/img/fb-icon.png";
import ig_icon from "../assets/img/ig-icon.png";
import tw_icon from "../assets/img/tw-icon.png";
import yt_icon from "../assets/img/yt-icon.png";
import Li_icon from "../assets/img/Li-icon.png";

function Footer() {
  return (
    <>
      <footer>
        <div className="user-footer-bg">
          <div className="user-image-text">
            <div className="user-footer-details">
              <h1>About Edutor App</h1>
              <p>
                Edutor App is a K-12 education platform aiming at organizing
                Teachers’ contents & making it accessible for every student.
                <br />
                <br />
                Edutor App is a mobile application that enables teachers to
                create, organize & share all kinds of contents very easily. By
                organizing contents we are significantly increasing the lifetime
                value of content.
              </p>
              <h1>How to use ?</h1>
              <p>
                Teachers can create or share quizzes, videos, exams, PDFs,
                images at a single place in a much organized way. Teachers can
                keep their contents organized by subject wise, standard wise,
                content type wise, date added wise, Playlist wise.
                <br />
                <br />
                Students can access any of the contents by just following those
                teachers. Discover content from 75,000+ contents created by
                teachers.
              </p>
              <h1>Why is the Edutor App different ?</h1>
              <p>
                Our core belief sets us apart from rest. At the core, we
                strongly believe that our 1cr local teachers are the true
                pillars of our society, and that their knowledge and expertise
                are invaluable in shaping the next generation of leaders and
                innovators. That's why we've made it our mission to empower
                these teachers with the tools they need to excel in their craft.
                Our platform is designed to empower them by keeping their
                valuable content organized, so they can focus on what they do
                best: teaching and inspiring young minds.
              </p>
              <h1>Digital Teacher Mission</h1>
              <p>
                Edutor App's Digital Teacher Mission is focused on empowering
                local teachers by providing them with the tools and resources to
                create digital content. The initiative aims to create a
                community-based approach to digital content creation, where
                teachers collaborate with each other in teams to produce
                high-quality, engaging educational material. By forming 500+
                teams of teachers within the next 2 months, Digital Teacher
                Mission aims to foster a network of teachers who can work
                together to enhance the learning experience of students across
                the country. The mission represents a critical step towards
                revolutionizing education and creating a brighter future for the
                next generation, and Edutor App invites all educators to join
                them on this exciting journey.
              </p>
              <h1>NMMS Exam Mission</h1>
              <p>
                Gujarat’s best teachers have created lots of useful contents on
                Edutor App which includes more than 2000 NMMS practice quizzes.
                In order to make these contents accessible to all students,
                Edutor App has initiated NMMS Exam Mission in which everyday 15
                practice quizzes created by best teachers are shared to more
                than 10,000 students. WhatsApp groups of every district of
                Gujarat are created. If you want to join your district group,{" "}
                <a href="">click here</a>. Besides that videos of every topic of
                NMMS are shared on the website. You can watch it{" "}
                <a href="">here</a>.
              </p>
            </div>
            <div className="user-footer-copy">
              <div className="user-footer-iconwith-copy">
                <div className="user-footer-icon">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/EdutorAppOfficial?mibextid=ZbWKwL"
                  >
                    <img src={fb_icon} alt="fb_icon" />
                    <h5>Facebook</h5>
                  </a>
                </div>
                <div className="user-footer-icon">
                  <a
                    target="_blank"
                    href="https://instagram.com/edutorapp?igshid=YmMyMTA2M2Y="
                  >
                    <img src={ig_icon} alt="ig_icon" />
                    <h5>Instagram</h5>
                  </a>
                </div>
                <div className="user-footer-icon">
                  <a target="_blank" href="https://twitter.com/EdutorApp">
                    <img src={tw_icon} alt="tw_icon" />
                    <h5>Twitter</h5>
                  </a>
                </div>
                <div className="user-footer-icon">
                  <a target="_blank" href="https://youtube.com/@edutorapp188">
                    <img src={yt_icon} alt="yt_icon" />
                    <h5>You Tube</h5>
                  </a>
                </div>
                <div className="user-footer-icon">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/edutor-app/"
                  >
                    <img src={Li_icon} alt="Li_icon" />
                    <h5>Linkedin</h5>
                  </a>
                </div>
              </div>
              <div className="user-footer-copyright">
                <h6>
                  Copyright © {new Date().getFullYear()} NIB EDUSOFT PRIVATE
                  LIMITED
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
