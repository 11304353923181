import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AddContent = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const [teamname, setteamname] = useState(null);
  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate("/admin/login");
    } else {
    }
  };

  useEffect(() => {
    UserdValid();
  }, []);

  //Single Team Name Display
  useEffect(() => {
    const fetchTeamname = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/team/${id}`);
      setteamname(res.data);
    };
    fetchTeamname();
  }, []);

  //Select std TeamInfo
  const [teaminfo, setteaminfo] = useState([]);
  const [getteaminfo, setteaminfodata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteaminfo/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setteaminfodata(data);
      setteaminfo(data.teaminfo);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //teacher list select
  const [teamteacher, setteacher] = useState([]);
  const [getteacherdata, setteacherdata] = useState([]);

  const GetTeacher = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteacher/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    // console.log(data);
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setteacherdata(data);
      setteacher(data.teacherdetail);
    }
  };
  useEffect(() => {
    GetTeacher();
  }, []);

  //!Add Content
  const [std, setstd] = useState("");
  const [contenttype, setcontenttype] = useState("");
  const [topic, settopic] = useState("");
  const [link, setlink] = useState("");
  const [teachername, setteachername] = useState("");
  const [subject, setsubject] = useState("");
  const [day, setday] = useState("");

  const AddContent = async (e) => {
    e.preventDefault();

    const data = {
      std,
      contenttype,
      topic,
      link,
      teachername,
      subject,
      day,
    };

    try {
      await axios.patch(`${API_BASE_URL}/api/addcontent/${id}`, data);
      alert("New Content Data Added!");
      GetContent();
      setstd("");
      setcontenttype("");
      settopic("");
      setlink("");
      setteachername("");
      setsubject("");
      setday("");
    } catch (error) {
      console.log("Error Added team info:", error.message);
      alert("Something Went Wrong, Please Try Again Later!");
    }
  };

  //? Get Content API
  const [content, setcontent] = useState([]);
  const [getcontent, setcontentdata] = useState([]);

  const GetContent = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getcontent/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      window.alert("error in get data");
    } else {
      setcontentdata(data);
      setcontent(data.addcontent);
    }
  };
  useEffect(() => {
    GetContent();
  }, []);

  //Delete Content DTM
  const deleteContent = async (nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (confirmDelete) {
      const res1 = await fetch(
        `${API_BASE_URL}/api/deletecontent/${nid}/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data1 = await res1.json();

      if (data1) {
        GetContent();
        window.alert("Record deleted successfully!");
      } else {
        alert("Something Went Wrong, Please Try Again Later!");
      }
    } else {
      GetContent();
    }
  };

  //View MOre
  const [displayCount, setDisplayCount] = useState(5);
  const handleViewMore = () => {
    setDisplayCount(displayCount + 5);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Content</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Add Content</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-1">
              {/* Vertical Form */}
              {teamname ? (
                <>
                  <h5>
                    <span style={{ color: "#000" }}>{teamname.teamname}</span> -
                    Content details
                  </h5>
                </>
              ) : (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
              <form
                method="post"
                onSubmit={AddContent}
                encType="multipart/form-data"
              >
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Enter Std:</label>
                  <select
                    className="form-control mt-1"
                    name="quizsubject"
                    value={std}
                    onChange={(e) => setstd(e.target.value)}
                  >
                    <option selected>Select Std</option>
                    {teaminfo.map((ele, i) => (
                      <option value={ele.teachername}>{ele.std}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Content Type:
                  </label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={contenttype}
                    onChange={(e) => setcontenttype(e.target.value)}
                  >
                    <option selected>Select Content</option>
                    <option value="Quiz">ડેઈલી ક્વિઝ</option>
                    <option value="Magictest">મેજિક ટેસ્ટ</option>
                    <option value="Vacationutsav">વેકેશન ઉત્સવ</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Topic:
                  </label>
                  <input
                    name="topic"
                    type="text"
                    value={topic}
                    onChange={(e) => settopic(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Content Link:
                  </label>
                  <input
                    name="link"
                    type="url"
                    value={link}
                    onChange={(e) => setlink(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Link"
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Teacher name
                  </label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={teachername}
                    onChange={(e) => setteachername(e.target.value)}
                  >
                    <option selected>Select Teacher name</option>
                    {teamteacher.map((teacher, i) => (
                      <option value={teacher.teachername}>
                        {teacher.teachername}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Quiz Subject
                  </label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={subject}
                    onChange={(e) => setsubject(e.target.value)}
                  >
                    <option selected>Select Subject</option>
                    {teamteacher.map((teacher, i) => (
                      <option value={teacher.subject}>{teacher.subject}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">Day</label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={day}
                    onChange={(e) => setday(e.target.value)}
                  >
                    <option selected>Select Day</option>
                    <option value="સોમવાર">સોમવાર</option>
                    <option value="મંગળવાર">મંગળવાર</option>
                    <option value="બુધવાર">બુધવાર</option>
                    <option value="ગુરુવાર">ગુરુવાર</option>
                    <option value="શુક્રવાર">શુક્રવાર</option>
                    <option value="શનિવાર">શનિવાર</option>
                  </select>
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Add Content
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <hr></hr>

              <div className="scrolltable" style={{ overflow: "auto" }}>
                <h4>Lasted Content</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Std</th>
                      <th scope="col">Type</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Link</th>
                      <th scope="col">Teacher name</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Day</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  {content
                    .slice(0)
                    .reverse()
                    .map((ele, i) => (
                      <tbody>
                        <tr key={`${i}-${ele._id}`}>
                          <td>{i + 1}</td>
                          <td>{ele.std}</td>
                          <td>{ele.contenttype}</td>
                          <td style={{ maxWidth: "200px" }}>{ele.topic}</td>
                          <td style={{ maxWidth: "300px" }}>{ele.link}</td>
                          <td>{ele.teachername}</td>
                          <td>{ele.subject}</td>
                          <td>{ele.day}</td>
                          <td>
                            <Link to={`/dtm/edit-content/${ele._id}/${id}`}>
                              <button className="btn btn-success">
                                <i class="bi bi-pencil-square"></i>
                              </button>
                            </Link>
                            &nbsp;
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteContent(ele._id)}
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
                {/* <div class="text-center">
                  {content.length > displayCount && (
                    <button
                      className="btn btn-primary"
                      onClick={handleViewMore}
                    >
                      View More
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddContent;
