import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import API_BASE_URL from "../../../../api"

const AddContent = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const { obid } = useParams();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  //Single Team Name
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${API_BASE_URL}/api/single/content/${id}/${obid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = res.data;
      setstd(data[0].addcontent[0].std);
      setcontenttype(data[0].addcontent[0].contenttype);
      settopic(data[0].addcontent[0].topic);
      setlink(data[0].addcontent[0].link);
      setteachername(data[0].addcontent[0].teachername);
      setsubject(data[0].addcontent[0].subject);
      setday(data[0].addcontent[0].day);
    };
    fetchData();
  }, []);

  const [std, setstd] = useState("");
  const [contenttype, setcontenttype] = useState("");
  const [topic, settopic] = useState("");
  const [link, setlink] = useState("");
  const [teachername, setteachername] = useState("");
  const [subject, setsubject] = useState("");
  const [day, setday] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_BASE_URL}/api/updatecontent/${id}/${obid}`, {
        std,
        contenttype,
        topic,
        link,
        teachername,
        subject,
        day,
      });
      alert("Content Updated!");
      navigate(`/dtm/add-content/${obid}`);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };
  return (
    <>
      <Header />
      <Sidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Content</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Edit Content</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-1">
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={handleUpdate}
              >
                <div className="form-group">
                  <div className="col-12 mt-2">
                    <label htmlFor="inputNanme4" className="form-label">
                      Enter Std:
                    </label>
                    <input
                      name="std"
                      value={std}
                      onChange={(e) => setstd(e.target.value)}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                    />
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Content Type:
                  </label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={contenttype}
                    onChange={(e) => setcontenttype(e.target.value)}
                  >
                    <option selected>Select Content</option>
                    <option value="Quiz">ડેઈલી ક્વિઝ</option>
                    <option value="Magictest">મેજિક ટેસ્ટ</option>
                    <option value="Vacationutsav">વેકેશન ઉત્સવ</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Topic:
                  </label>
                  <input
                    name="topic"
                    value={topic}
                    onChange={(e) => settopic(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Content Link:
                  </label>
                  <input
                    name="link"
                    value={link}
                    onChange={(e) => setlink(e.target.value)}
                    type="url"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Link"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher name:
                  </label>
                  <input
                    name="teachername"
                    value={teachername}
                    onChange={(e) => setteachername(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Quiz Subject:
                  </label>
                  <input
                    name="subject"
                    value={subject}
                    onChange={(e) => setsubject(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Day:
                  </label>
                  <input
                    name="day"
                    value={day}
                    onChange={(e) => setday(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Update Content
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddContent;
