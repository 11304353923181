import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./components/Blogheader";
import Footer from "../../components/Footer";

const Skeleton = () => {
  return (
    <div className="col">
      <div className="wrapper">
        <div id="skeleton-wrapper" className="skeleton-wrapper">
          {/* Adjust the size of the skeleton image */}
          <div id="skeleton-image" className="skeleton-image skeleton-border" style={{ width: "475%", height: "250%" }}></div>
        </div>
        <div className="p-4">
          {/* Adjust the size of the skeleton title and author */}
          <h1 className="h4 fw-bold text-dark rounded-1 skeleton-text" style={{ width: "80%", height: "20px" }}></h1>
          <h2 className="d-flex mt-3">
            <p className="h6 text-dark rounded-2 skeleton-text" style={{ width: "40%", height: "15px" }}></p>
            <p className="h6 rounded" style={{ color: "#EB5885", width: "20%", height: "15px" }}></p>
          </h2>
        </div>
      </div>
    </div>
  );
};

function Blogcategory() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  //Category Poster
  const { id } = useParams();
  const [category, setcategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/category/${id}`);
      setIsLoading(false);
      setcategory(res.data);
      handleOptionClick(res.data.categoryname);
    };
    fetchData();
  }, []);

  //Blog post in category page
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/get-blog`);
      setBlog(res.data);
    };
    fetchData();
  }, []);

  //Filter Category
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (option) => {
    const options = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(options);
  };

  const filterItems = () => {
    if (selectedOptions.length === 0) {
      return blog;
    } else {
      return blog.filter((item) => {
        return selectedOptions.some((option) => item.category.includes(option));
      });
    }
  };


  return (
    <>
      <Header />
      <>
        {isLoading ? (
          Array.from({ length: 1 }).map((_, index) => (
            <Skeleton key={index} />
          ))
        ) : (
      category && (
        <div id="blog_category_container">
          <div className="col">
            <img
              src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Blog/Category/Poster/${category.categoryposter}`}
              alt=""
            />
            <h1 className="fw-bold">{category.categoryname}</h1>
          </div>
        </div>
      )
      )}
    </>

      <div id="blog_category_content" className="container d-grid">
        {filterItems()
          .slice()
          .reverse()
          .map((blog, index) => (
            <Link to={`/blog/post/${blog._id}`}>
              <div className="row align-items-center">
                <div className="col">
                  <img
                    src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Blog/Images/${blog.images}`}
                    alt=""
                  />
                </div>
                <div id="blog_category_detail" className="col">
                  <h1 className="fw-bold">{blog.tittle}</h1>
                  <h3>{blog.author}</h3>
                  <div className="mb-0 d-flex">
                    <em>{blog.minute}</em>
                    {/* <span className="mx-3 h5 fw-bold">.</span>
                    <p>Like</p> */}
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>

      <Footer />
    </>
  );
}

export default Blogcategory;
