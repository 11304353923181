import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import API_BASE_URL from "../../../api";

const CreateCategory = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  const [categoryname, setcategoryname] = useState("");
  const [categoryposter, setcategoryposter] = useState("");
  const setimgfile = async (e) => {
    setcategoryposter(e.target.files[0]);
  };

  const [categoryicon, setcategoryicon] = useState("");
  const handleFileTwoChange = (event) => {
    setcategoryicon(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("categoryname", categoryname);
    formData.append("categoryposter", categoryposter);
    formData.append("categoryicon", categoryicon);
    
    try {
      await axios.post(`${API_BASE_URL}/api/createcategory`,formData);      
      alert("New Category Added!");
    } catch (error) {
      console.log("Error creating new Category:", error.message);
    }
  };

  //Get Category 
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getcategory`);
      setBlog(res.data);
    };
    fetchData();
  }, []);

  //Delete Category
  const [render, setRender] = useState(false);
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this Category?");

    if (confirmed) {
      await axios.delete(`${API_BASE_URL}/api/deletecategory/${id}`);
      setRender((prevState) => !prevState);
      alert("Category is deleted successfully!");
      window.location.reload();
    }
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create New Blog</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">Blog</li>
              <li className="breadcrumb-item active">Create New Category</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-2">
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Category name:
                  </label>
                  <input
                    type="text"
                    value={categoryname}
                    onChange={(e) => setcategoryname(e.target.value)}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Category Poster:
                  </label>
                  <input
                    type="file"
                    onChange={setimgfile}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>


                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Category Icon:
                  </label>
                  <input
                    type="file"
                    name="images"
                    onChange={handleFileTwoChange}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                  Create Category
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
          <div className="card">
          <div className="card-body">
            <div className="scrolltable" style={{ overflow: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Blog category</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {blog ? (
                  <>
                    <tbody>
                      {blog.slice().reverse().map((blog, index) => (
                        <tr key={`${index}-${blog._id}`}>
                          <td>{index + 1}</td>
                          <td>{blog.categoryname}</td>
                          <td>
                            <button className="btn btn-danger" onClick={() => handleDelete(blog._id)}><i class="bi bi-trash"></i></button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <p>Loading team name...</p>
                )
                }
              </table>
            </div>
          </div>
        </div>
        </div>
        
      </main>

        
        
    </>
  );
};

export default CreateCategory;