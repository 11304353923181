import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";

const CreateTeam = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [teamname, setTeamname] = useState("");
  const [teamstd, setteamstd] = useState("");
  const [teamphoto, setFile] = useState("");

  const setimgfile = async (e) => {
    setFile(e.target.files[0]);
  };

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("teamname", teamname);
    formData.append("teamstd", teamstd);
    formData.append("teamphoto", teamphoto);

    try {
      await axios.post(`${API_BASE_URL}/api/createteam`, formData);
      alert("New Team Added!");
      navigate("/dtm-admin");
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error creating teacher profile:", error.message);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create DTM Team Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Add DTM Team Profile</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-2">
              {/* Vertical Form */}
              <h5>Team Details</h5>
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Team name:
                  </label>
                  <input
                    defaultValue={`ટીમ ${teamname}`}
                    onChange={(e) => setTeamname(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Std:
                  </label>
                  <input
                    defaultValue={`ધોરણ ${teamstd}`}
                    onChange={(e) => setteamstd(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Std"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Team Photo:
                  </label>
                  <input
                    name="teamphoto"
                    type="file"
                    onChange={setimgfile}
                    className="form-control"
                    id="exampleInputPassword1"
                    required
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Submit
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateTeam;
