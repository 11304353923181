import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const TeamInfo = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate("/admin/login");
    } else {
    }
  };

  useEffect(() => {
    UserdValid();
  }, []);

  //Single Team Name
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/team/${id}`);
      setUsers(res.data);
    };
    fetchData();
  }, [render]);

  //Team Add Info
  const [std, setStd] = useState("");
  const [grouplink, setGrouplink] = useState("");
  const SubmitTeamInfo = async (e) => {
    e.preventDefault();

    const data = {
      std,
      grouplink,
    };

    try {
      await axios.patch(`${API_BASE_URL}/api/addteaminfo/${id}`, data);
      alert("Team Info Data Added!");
      GetTeamInfo();
      setStd("");
      setGrouplink("");
      navigate(`/dtm/team-info/${id}`, { replace: true });
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error Added team info:", error.message);
    }
  };

  //Display Get TeamInfo
  const [teaminfo, setarr] = useState([]);
  const [getuserdata, setuserdata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteaminfo/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setuserdata(data);
      setarr(data.teaminfo);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //Delete Team info
  const deleteInfo = async (nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      const res1 = await fetch(
        `${API_BASE_URL}/api/deleteteaminfo/${nid}/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data2 = await res1.json();
      if (data2) {
        GetTeamInfo();
        window.alert("Record deleted successfully!");
      } else {
        window.alert("Error deleting record");
      }
    }
  };

  //Teacher Details
  const [teachername, setTeachername] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [subject, setSubject] = useState("");
  const [day, setDay] = useState("");
  const [teacherprofilelink, setteacherprofilelink] = useState("");
  const [teacherphoto, setFile] = useState("");

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
  };

  const SubmitTeacher = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("teachername", teachername);
    formData.append("schoolname", schoolname);
    formData.append("subject", subject);
    formData.append("day", day);
    formData.append("teacherprofilelink", teacherprofilelink);
    formData.append("teacherphoto", teacherphoto);

    try {
      await axios.patch(`${API_BASE_URL}/api/addteacher/${id}`, formData);
      alert("Teacher Data Added!");
      GetTeacher();
      setTeachername("");
      setSchoolname("");
      setSubject("");
      setDay("");
      setteacherprofilelink("");
      setFile("");
      navigate(`/dtm/team-info/${id}`, { replace: true });
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error Added teacher profile:", error.message);
    }
  };

  //Display Get TeamTeacher
  const [teamteacher, setteacher] = useState([]);
  const [getteacherdata, setteacherdata] = useState([]);

  const GetTeacher = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteacher/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      window.alert("Please contact admin!");
    } else {
      setteacherdata(data);
      setteacher(data.teacherdetail);
    }
  };
  useEffect(() => {
    GetTeacher();
  }, []);

  //Delete Teacher PRofile
  const deleteTeacher = async (nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      const res = await fetch(
        `${API_BASE_URL}/api/deleteteacher/${nid}/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (data) {
        GetTeacher();
        window.alert("Teacher Profile deleted successfully!");
      } else {
        window.alert("Error deleting record");
      }
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Team Info</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Add DTM Team Info</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-1">
              {user ? (
                <>
                  <h5>{user.teamname}</h5>
                </>
              ) : (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
              <form
                method="post"
                onSubmit={SubmitTeamInfo}
                encType="multipart/form-data"
              >
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Enter Std:</label>
                  <select
                    className="form-control mt-1"
                    name="std"
                    value={std}
                    onChange={(e) => setStd(e.target.value)}
                  >
                    <option selected>Select Std</option>
                    <option value="ધોરણ 5">ધોરણ 5</option>
                    <option value="ધોરણ 6">ધોરણ 6</option>
                    <option value="ધોરણ 7">ધોરણ 7</option>
                    <option value="ધોરણ 8">ધોરણ 8</option>
                    <option value="ધોરણ 9">ધોરણ 9</option>
                    <option value="ધોરણ 10">ધોરણ 10</option>
                    <option value="ધોરણ 11">ધોરણ 11</option>
                    <option value="ધોરણ 12">ધોરણ 12</option>
                    <option value="જનરલ ટીમ">જનરલ ટીમ</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Whatsapp Group Link:
                  </label>
                  <input
                    name="grouplink"
                    type="url"
                    value={grouplink}
                    onChange={(e) => setGrouplink(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Link"
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Add Team Info
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <hr></hr>

              <div className="scrolltable" style={{ overflow: "auto" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Standard</th>
                      <th scope="col">Group Link</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  {teaminfo.length !== 0 ? (
                    teaminfo
                      .slice()
                      .reverse()
                      .map((info, index) => (
                        <tbody key={`${index}-${info._id}`}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{info.std}</td>
                            <td style={{ maxWidth: "300px" }}>
                              {info.grouplink}
                            </td>
                            <td>
                              {/* <button className="btn btn-success">
                                <i class="bi bi-pencil-square"></i>
                              </button> */}
                              &nbsp;
                              <button
                                className="btn btn-danger"
                                onClick={() => deleteInfo(info._id)}
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                              &nbsp;&nbsp;
                              {/* <Link to={`/dtm/add-content/${info._id}/${id}`}>
                              <button className="btn btn-primary">Add Content</button>
                            </Link> */}
                            </td>
                          </tr>
                        </tbody>
                      ))
                  ) : (
                    <h4 className="mt-3">No record</h4>
                  )}
                </table>
              </div>
            </div>

            {/* Teacher */}
            <hr className="hrstyle"></hr>
            {user ? (
              <>
                <Link className="btn btn-primary mx-5" to={`/dtm/make-banner/${id}`}>{user.teamname} -Add Pop-Up</Link>
              </>
            ) : (
              <div></div>
            )}
            <hr className="hrstyle"></hr>
            <div className="card-body mt-1">
              {/* Vertical Form */}
              <h5>Teacher details</h5>
              <form
                method="post"
                onSubmit={SubmitTeacher}
                encType="multipart/form-data"
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher name:
                  </label>
                  <input
                    name="teachername"
                    type="text"
                    value={teachername}
                    onChange={(e) => setTeachername(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School name:
                  </label>
                  <input
                    name="schoolname"
                    type="text"
                    value={schoolname}
                    onChange={(e) => setSchoolname(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="School name"
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Quiz Subject
                  </label>
                  <select
                    className="form-control"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option selected>Select Subject</option>
                    <option value="ગણિત">ગણિત</option>
                    <option value="ગુજરાતી">ગુજરાતી</option>
                    <option value="સામાજિક વિજ્ઞાન">સામાજિક વિજ્ઞાન </option>
                    <option value="અંગ્રેજી">અંગ્રેજી</option>
                    <option value="વિજ્ઞાન">વિજ્ઞાન </option>
                    <option value="હિન્દી">હિન્દી</option>
                    <option value="સંસ્કૃત">સંસ્કૃત</option>
                    <option value="આસપાસ">આસપાસ</option>
                    <option value="અર્થશાસ્ત્ર">અર્થશાસ્ત્ર</option>
                    <option value="સમાજશાસ્ત્ર">સમાજશાસ્ત્ર</option>
                    <option value="તત્વજ્ઞાન">તત્વજ્ઞાન </option>
                    <option value="ભૂગોળ">ભૂગોળ</option>
                    <option value="મનોવિજ્ઞાન">મનોવિજ્ઞાન</option>
                    <option value="વાણિજ્ય અને વ્યવસ્થા">
                      વાણિજ્ય અને વ્યવસ્થા
                    </option>
                    <option value="નામાનાં મૂળતત્વો">નામાનાં મૂળતત્વો</option>
                    <option value="આંકડાશાસ્ત્ર">આંકડાશાસ્ત્ર</option>
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">Day</label>
                  <select
                    className="form-control"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  >
                    <option selected>Select Day</option>
                    <option value="સોમવાર">સોમવાર</option>
                    <option value="મંગળવાર">મંગળવાર</option>
                    <option value="બુધવાર">બુધવાર</option>
                    <option value="ગુરુવાર">ગુરુવાર</option>
                    <option value="શુક્રવાર">શુક્રવાર</option>
                    <option value="શનિવાર">શનિવાર</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher Profile Link:
                  </label>
                  <input
                    name="teacherprofilelink"
                    type="url"
                    value={teacherprofilelink}
                    onChange={(e) => setteacherprofilelink(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Profile Link"
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher Photo:
                  </label>
                  <input
                    name="teacherphoto"
                    type="file"
                    onChange={setimgfile}
                    className="form-control"
                    id="exampleInputPassword1"
                    
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Add Teacher
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <hr></hr>

              <div className="scrolltable" style={{ overflow: "auto" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Teacher name</th>
                      <th scope="col">School name</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Day</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  {teamteacher.length !== 0 ? (
                    teamteacher.map((teacher, i) => (
                      <tbody key={`${i}-${teacher._id}`}>
                        <tr>
                          <td>{i + 1}</td>
                          <td>{teacher.teachername}</td>
                          <td>{teacher.schoolname}</td>
                          <td>{teacher.subject}</td>
                          <td>{teacher.day}</td>
                          <td>
                            <Link to={`/dtm/edit-teacher/${teacher._id}/${id}`}>
                              <button className="btn btn-success">Edit</button>
                            </Link>
                            &nbsp;
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteTeacher(teacher._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <h4 className="mt-3">No Teacher List</h4>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TeamInfo;
