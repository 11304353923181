import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const EditTeacherProfile = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  //Get Single Teacher Profile
  const { id } = useParams();
  const [team, setUsers] = useState(null);
  const [render, setRender] = useState(false);


  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/gyansadhana/single/teacher/${id}`);
      const data = res.data;
      setTeacherName(data.teachername);
      setSchoolName(data.schoolname);
      setSubject(data.subject);
    };
    fetchData();
  }, [render]);

  const navigate = useNavigate();
  const [teachername, setTeacherName] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const [subject, setSubject] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${API_BASE_URL}/api/gyansadhana/update-teacher/${id}`, { teachername, schoolname, subject });
      const data = response.data
      alert("Teacher Profile Updated!");
      navigate("/gyansadhana-admin");

    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.error('Error updating item:', error);
    }
  };

  return (
    <>
    <Header/>
    <Sidebar/>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Teacher Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="../dashboard.php">Home</a>
              </li>
              <li className="breadcrumb-item">Gyan Sadhana</li>
              <li className="breadcrumb-item active">Edit Teacher Profile</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        {/* <section class="section">

         
        </div> */}
        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              {/* Vertical Form */}
              <form method="post" encType="multipart/form-data" onSubmit={handleUpdate} >
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher name:
                  </label>

                  <input
                  name="name"
                  type="text"
                  value={teachername}
                  onChange={(e) => setTeacherName(e.target.value)}
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School name:
                  </label>

                  <input
                    type="text"
                    value={schoolname}
                    onChange={(e) => setSchoolName(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Subject name:
                  </label>

                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <div className="text-center">
                <button type="submit" class="btn btn-primary mt-3">
               Update Teacher
              </button>
                  
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditTeacherProfile;
