import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function GyanSadhana() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [teacher, setUsers] = useState([]);
  const [render, setRender] = useState(false);

  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/gyansadhana/getteacher`);
      setUsers(res.data);  // Updated the users state with the fetchd data
    };
    fetchData();
  }, [render]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this record?");

    if (confirmed) {
      await axios.delete(`${API_BASE_URL}/api/gyansadhana/deleteteacher/${id}`);
      setRender((prevState) => !prevState);
      alert("Teacher data is deleted successfully!");
    }
  };


  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Gyan Sadhana Teacher list</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <p>Home</p>
              </li>
              <li className="breadcrumb-item">Gyan Sadhana</li>
              <li className="breadcrumb-item active">Teacher list</li>
            </ol>
          </nav>
          <Link to="/gyansadhana/create-tecacher-profile" className="btn btn-primary">
            Create Teacher profile
          </Link>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Gyan Sadhana Teacher list</h5>
            <div className="scrolltable" style={{overflow:"auto"}}>
              <table className="table table-bordered" >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Teacher name</th>
                    <th scope="col">School name</th>
                    <th scope="col">Action </th>
                    <th scope="col">Show Quiz </th>
                  </tr>
                </thead>
                <tbody>
                  {teacher.slice().reverse().map((teacher, index) => (
                    <tr key={`${index}-${teacher._id}`}>
                      <td>{index+1}</td>
                      <td>{teacher.teachername}</td>
                      <td>{teacher.schoolname}</td>
                      <td>
                        <Link to={`/gyansadhana/edit-tecacher-profile/${teacher._id}`}>
                          <button className="btn btn-success"><i class="bi bi-pencil-square"></i></button>
                        </Link>
                        &nbsp; &nbsp;
                        <button
                          onClick={() => handleDelete(teacher._id)}
                          className="btn btn-danger"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                      <td>
                      <Link to={`/gyansadhana/showquiz/${teacher._id}`}>
                        <button className="btn btn-primary"> <i class="bi bi-plus-circle-fill"></i> &nbsp; Quiz</button>
                      </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default GyanSadhana;
