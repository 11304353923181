import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";

const EditQuiz = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [subject, setSubject] = useState("");
  const [tittle, setTittle] = useState("");
  const [link, setLink] = useState("");

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const { obid } = useParams();

  // Single Quiz
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${API_BASE_URL}/api/navoday/single-quiz/${id}/${obid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = res.data;
      setSubject(data[0].addquiz[0].subject);
      setTittle(data[0].addquiz[0].tittle);
      setLink(data[0].addquiz[0].link);
    };
    fetchData();
  }, []);

  //Update Quiz 
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_BASE_URL}/api/navoday/update-quiz/${id}/${obid}`, {
        subject,
        tittle,
        link,
      });
      alert("Quiz Updated!");
      navigate("/navoday-admin");
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.error("Error updating item:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Quiz - Navoday</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Navoday</li>
              <li className="breadcrumb-item active">Edit Quiz</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form
                method="POST"
                className="row g-3"
                encType="multipart/form-data"
                onSubmit={handleUpdate}
              >
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Enter Subject:
                  </label>
                  <select
                    className="form-control mt-1"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option selected>Select Subject</option>
                    <option value="આકૃતિ આધારિત પ્રશ્નો">આકૃતિ આધારિત પ્રશ્નો</option>
                    <option value="ફકરા પરથી જવાબ">ફકરા પરથી જવાબ</option>
                    <option value="અંક ગણિત">અંક ગણિત</option>
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Quiz Tittle:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={tittle}
                    onChange={(e) => setTittle(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Quiz Link:
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    name="insert"
                  >
                    Update Quiz
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default EditQuiz;
