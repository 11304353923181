import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
// import API_BASE_URL from "../../../api";

function BlogMain() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [blog, setBlog] = useState([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/get-blog`);
      setBlog(res.data);
    };
    fetchData();
  }, [render]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this team?");

    if (confirmed) {
      await axios.delete(`${API_BASE_URL}/api/deleteblog/${id}`);
      setRender((prevState) => !prevState);
      alert("Blog is deleted successfully!");
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Blogs list</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <p>Home</p>
              </li>
              <li className="breadcrumb-item active">Blogs</li>
            </ol>
          </nav>

          <Link to="/blog/create-createcategory" className="btn btn-info">
            Create Category
          </Link>
          &nbsp;
          <Link to="/blog/create-new-blog" className="btn btn-primary">
          <i className="bi bi-patch-plus"></i> &nbsp; Upload New Blog
          </Link>
          
          
        </div>

        <div className="card">
          <div className="card-body">
            <div className="scrolltable" style={{ overflow: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Blog tittle</th>
                    <th scope="col">Category </th>
                    <th scope="col">Author</th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                {blog ? (
                  <>
                    <tbody>
                      {blog.slice().reverse().map((blog, index) => (
                        <tr key={`${index}-${blog._id}`}>
                          <td>{index + 1}</td>
                          <td>{blog.tittle}</td>
                          <td>{blog.category}</td>
                          <td>{blog.author}</td>
                          <td>
                            <Link to={`/blog/edit-blog/${blog._id}`}><button className="btn btn-success" ><i className="bi bi-pencil-square"></i></button>
                            </Link>&nbsp;
                            <button className="btn btn-danger" onClick={() => handleDelete(blog._id)}><i className="bi bi-trash"></i></button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <p>Loading team name...</p>
                )
                }

              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default BlogMain;
