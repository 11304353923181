import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from "react-router-dom";

const PopUp = () => {
    //Display Get TeamInfo
    const [teaminfo, setarr] = useState([]);
    const [getuserdata, setuserdata] = useState([]);
    const { id } = useParams();

    const GetTeamInfo = async () => {
        const res = await fetch(`http://localhost:9000/api/getteaminfo/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        })

        const data = await res.json();
        if (!data) {
            window.alert('error in get data')
        }
        else {
            setuserdata(data);
            setarr(data.teaminfo)
        }
    }
    useEffect(() => {
        GetTeamInfo();
    }, [])

    return (
        <>

            <div className="container">
                &nbsp;
                {/* to={`/dtm/add-content/${info._id}/${id}`} */}
                {teaminfo.length !== 0 ? (
                    teaminfo.map((info, index) => (
                        <div key={`${index}-${info._id}`}>
                            <Link to={`/dtm/add-content/${info._id}/${id}`}>
                                
                            </Link>
                            <Link to={`/popup/${info._id}/${id}`}>
                                <p>{info._id}</p>
                                <p>{id}</p>
                                <button className="btn btn-primary mb-3">{info.std}</button>
                            </Link>

                        </div>
                    ))
                ) : (
                    <h4 className="mt-3">No record</h4>
                )}
            </div>
        </>


    )
}

export default PopUp;