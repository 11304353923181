import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const DtmBanner = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate("/admin/login");
    } else {
    }
  };

  useEffect(() => {
    UserdValid();
  }, []);

  //Single Team Name
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/team/${id}`);
      setUsers(res.data);
    };
    fetchData();
  }, [render]);

  //Select std TeamInfo
  const [teaminfo, setteaminfo] = useState([]);
  const [getteaminfo, setteaminfodata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteaminfo/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setteaminfodata(data);
      setteaminfo(data.teaminfo);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //teacher list select
  const [teamteacher, setteacher] = useState([]);
  const [getteacherdata, setteacherdata] = useState([]);

  const GetTeacher = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteacher/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    // console.log(data);
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setteacherdata(data);
      setteacher(data.teacherdetail);
    }
  };
  useEffect(() => {
    GetTeacher();
  }, []);

  const [tittle, setPoster] = useState([]);
  const [std, setstd] = useState([]);
  const [teachername, setteachername] = useState([]);
  const [subject, setsubject] = useState([]);
  const [link, setpopuplink] = useState([]);

  const SubmitPopUp = async (e) => {
    e.preventDefault();

    const data = {
      tittle,
      std,
      teachername,
      subject,
      time,
      link,
    };

    try {
      await axios.patch(`${API_BASE_URL}/api/addpopup/${id}`, data);
      alert("New PopUp Added!");
      GetPopUp();
      setPoster("");
      setstd("");
      setteachername("");
      setsubject("");
      setpopuplink("");
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error Added teacher profile:", error.message);
    }
  };

  //Display Get PopUp
  const [popup, setpopup] = useState([]);

  const GetPopUp = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getpopup/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      window.alert("Please contact admin!");
    } else {
      setpopup(data.popup);
    }
  };
  useEffect(() => {
    GetPopUp();
  }, []);

  //Delete PopUp
  const deletePopUp = async (nid) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      const res = await fetch(`${API_BASE_URL}/api/deletepopup/${nid}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data) {
        GetPopUp();
        window.alert("PopUp deleted successfully!");
      } else {
        window.alert("Error deleting record");
      }
    }
  };

  //Today date and Time
  const defaultTime = "19:00";
  const today = new Date().toISOString().split("T")[0];
  const defaultDateTime = `${today}T${defaultTime}`;
  
  const [time, setTime] = useState(defaultDateTime);
  
  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  
  // ... rest of your code ...
  
  return (
    <>
      <Header />
      <Sidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          {user ? (
            <h1>Make Pop-Up - {user.teamname} </h1>
          ) : (
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          )}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Make Pop-Up</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-1">
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={SubmitPopUp}
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Poster name:
                  </label>
                  <input
                    name="poster"
                    type="text"
                    value={tittle}
                    onChange={(e) => setPoster(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="form-group mt-1">
                  <label htmlFor="exampleFormControlSelect1">Enter Std:</label>
                  <select
                    className="form-control mt-1"
                    name="quizsubject"
                    value={std}
                    onChange={(e) => setstd(e.target.value)}
                  >
                    <option selected>Select Std</option>
                    {teaminfo.map((ele, i) => (
                      <option value={ele.std}>{ele.std}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Teacher name:
                  </label>
                  <select
                    className="form-control"
                    name="quizsubject"
                    value={teachername}
                    onChange={(e) => setteachername(e.target.value)}
                  >
                    <option selected>Select Teacher name</option>
                    {teamteacher.map((teacher, i) => (
                      <option value={teacher.teachername}>
                        {teacher.teachername}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Subject name:
                  </label>
                  <select
                    className="form-control"
                    value={subject}
                    onChange={(e) => setsubject(e.target.value)}
                  >
                    <option selected>Select subject</option>
                    {teamteacher.map((teacher, i) => (
                      <option value={teacher.subject}>{teacher.subject}</option>
                    ))}
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Show Poster Time:
                  </label>
                  <input
                    name="time"
                    type="datetime-local"
                    value={time}
                    onChange={handleTimeChange}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Pop-Up Link"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Pop-Up Link:
                  </label>
                  <input
                    type="url"
                    value={link}
                    onChange={(e) => setpopuplink(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Pop-Up Link"
                    required
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Add Pop-Up
                  </button>
                </div>
              </form>
              {/* Vertical Form */}

              <hr></hr>

              <div className="scrolltable" style={{ overflow: "auto" }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Tittle</th>
                      <th scope="col">Std</th>
                      <th scope="col">Teacher name</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Time</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  {popup.slice(0).map((ele, i) => (
                    <tbody>
                      <tr key={`${i}-${ele._id}`}>
                        <td>{i + 1}</td>
                        <td>{ele.tittle}</td>
                        <td>{ele.std}</td>
                        <td style={{ maxWidth: "200px" }}>{ele.teachername}</td>
                        <td style={{ maxWidth: "300px" }}>{ele.subject}</td>
                        <td>{ele.time}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => deletePopUp(ele._id)}
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DtmBanner;
