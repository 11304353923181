import React, { useState, useEffect } from 'react';

const TestFile = () => {
  const [targetDateTime, setTargetDateTime] = useState(new Date().toISOString().slice(0, 16));
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [countdownExpired, setCountdownExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = new Date(targetDateTime) - now;

      if (timeDifference <= 0) {
        clearInterval(interval);
        setRemainingTime(0);
        setCountdownExpired(true);
      } else {
        setRemainingTime(timeDifference);
        setCurrentDateTime(now);
        setCountdownExpired(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDateTime]);

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  return (
    <div>
      <div>
        <p>Current Date and Time: {currentDateTime.toLocaleString()}</p>
      </div>
      <div>
        <label htmlFor="datetime">Select a date and time:</label>
        <input
          type="datetime-local"
          id="datetime"
          value={targetDateTime}
          onChange={(e) => setTargetDateTime(e.target.value)}
        />
      </div>
      <div>
        {countdownExpired ? (
          <p>Countdown expired! <button className='btn btn-primary'>Start</button></p>
        ) : (
          <p>Countdown to {new Date(targetDateTime).toLocaleString()}: {days}d {hours}h {minutes}m {seconds}s</p>
        )}
      </div>
    </div>
  );
};


export default TestFile;
