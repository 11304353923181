import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import API_BASE_URL from "../../../api";

const EditBlog = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  //Single Team Name
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/blog/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { tittle, category, author, content, minute } = res.data;
      setTittle(tittle);
      setCategory(category);
      setAuthor(author);
      seContent(content);
      setMinute(minute);
    };
    fetchData();
  }, [render]);

  const [tittle, setTittle] = useState("");
  const [category, setCategory] = useState("");
  const [author, setAuthor] = useState("");
  const [content, seContent] = useState("");
  const [minute, setMinute] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_BASE_URL}/api/updateblog/${id}`, {
        tittle,
        category,
        author,
        content,
        minute,
      });
      alert("Blog Updated!");
      navigate("/blog-main");
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Update Blog</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">Blog</li>
              <li className="breadcrumb-item active">Update New Blog</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-2">
              {/* Vertical Form */}
              <h5>Blog Details</h5>
              <form method="post" encType="multipart/form-data" onSubmit={handleUpdate}>
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Blog Tittle:
                  </label>
                  <input
                    type="text"
                    value={tittle}
                    onChange={(e) => setTittle(e.target.value)}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Enter Blog Category:
                  </label>
                  <select className="form-control mt-1" value={category}
                   onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Select Category</option>
                    <option value="AI">AI</option>
                    <option value="ડિજિટલ કન્ટેન્ટ">ડિજિટલ કન્ટેન્ટ</option>
                    <option value="ટેક્નોલોજી અને શિક્ષણ">
                      ટેક્નોલોજી અને શિક્ષણ
                    </option>
                    <option value="મૂલ્ય શિક્ષણ">મૂલ્ય શિક્ષણ</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter author name:
                  </label>
                  <input
                    type="text"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    className="form-control"
                    placeholder="Author name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Content:
                  </label>
                  <textarea
                    type="text"
                    value={content}
                    onChange={(e) => seContent(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Write your content..."
                    required
                    style={{ height: 400 }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter minute of reading this blog:
                  </label>
                  <input
                    type="text"
                    value={minute}
                    onChange={(e) => setMinute(e.target.value)}
                    className="form-control"
                    placeholder="Minute of reading  blog"
                    required
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Upload Blog
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditBlog;
