import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../assets/css/user.css";

import user_1 from "../assets/img/User/user_1.png";
import user_2 from "../assets/img/User/user_2.png";
import user_3 from "../assets/img/User/user_3.png";
import user_4 from "../assets/img/User/user_4.png";
import contact_icon_1 from "../assets/img/User/contact_icon_1.png";
import contact_icon_2 from "../assets/img/User/contact_icon_2.png";
function User() {
  return (
    <>
      <Header />
      <div id="user_container" className="container">
        <div className="row mt-5 align-items-center">
          <div id="user_img" className="col">
            <img src={user_3} alt="" className="img-fluid" />
          </div>
          <div id="user_details" className="col">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              NMMS પરીક્ષા મિશન
            </h1>
            <p>
              રાજ્યનાં શ્રેષ્ઠ શિક્ષકો દ્વારા NMMS ની સંપૂર્ણ નિશુલ્ક તૈયારી
            </p>
            <Link
              to="/nmms-exam-mission"
              className="m-0 btn btn-primary px-5 rounded-5"
            >
              જુઓ
            </Link>
          </div>
        </div>

        <div className="row mt-5 align-items-center">
          <div id="user_img" className="col">
            <img src={user_2} alt="" className="img-fluid" />
          </div>
          <div id="user_details" className="col">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              ડિજિટલ શિક્ષક મિશન
            </h1>
            <p>
              દરેક શિક્ષકને ડિજિટલ કન્ટેન્ટ બનાવતાં કરવાનું રાજ્યનું એકમાત્ર
              નિશુલ્ક મિશન
            </p>
            <Link
              to="/digital-teacher-mission"
              className="m-0 btn btn-primary px-5 rounded-5"
            >
              જુઓ
            </Link>
          </div>
        </div>

        <div className="row mt-5 align-items-center">
          <div id="user_img" className="col">
            <img src={user_1} alt="" className="img-fluid" />
          </div>
          <div id="user_details" className="col">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              જ્ઞાન સાધના પરીક્ષા મિશન
            </h1>
            <p>
              રાજ્યનું સૌથી મોટું મિશન : 40 હજારથી વધુ વિદ્યાર્થીઓને નિશુલ્ક
              તૈયારી કરાવવાનો લક્ષ
            </p>
            <Link
              to="gyan-sadhana-exam-mission"
              className="m-0 btn btn-primary px-5 rounded-5"
            >
              જુઓ
            </Link>
          </div>
        </div>

        <div className="row mt-5 align-items-center">
          <div id="user_img" className="col">
            <img src={user_4} alt="" className="img-fluid" />
          </div>
          <div id="user_details" className="col">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              નવોદય પરીક્ષા મિશન
            </h1>
            <p>
              રાજ્યનાં શ્રેષ્ઠ શિક્ષકો દ્વારા નવોદય પરીક્ષાની તદ્દન નિશુલ્ક
              તૈયારી
            </p>
            <Link
              to="/navodaya-exam-mission"
              className="m-0 btn btn-primary px-5 rounded-5"
            >
              જુઓ
            </Link>
          </div>
        </div>
      </div>

      {/* contact */}

      <div id="contact_container" className="container">
        <div className="d-flex align-items-center justify-content-center align-content-center">
          <div>
            <img
              src={contact_icon_1}
              alt="contact_icon_1"
              className="img-fluid"
            />
          </div>
          <div>
            <h1 className="fw-bold text-primary mx-5">મદદ માટે સંપર્ક કરો</h1>
          </div>
          <div>
            <img
              src={contact_icon_2}
              alt="contact_icon_2"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div id="contact_container" className="container mb-5 d-grid">
        <div>
          <div className="text-left mb-4">
            <h2 className="fw-bold">ડિજિટલ શિક્ષક મિશન</h2>
          </div>
          <div className="d-flex">
            <div className="row bg-white py-5 mx-5 rounded-5">
              <div>
                <div className="text-center">
                  <h3>દલપત કુમાર</h3>
                </div>
              </div>
              <div className="text-center mt-3">
                <Link
                  to="tel:+918866206190"
                  className="btn btn-primary fw-bold rounded-5 px-5 pb-2"
                >
                  કોલ કરો
                </Link>
              </div>
              <div className="text-center mt-3">
                <Link
                  to="https://api.whatsapp.com/send?phone=918866206190&text=ડિજિટલ શિક્ષક મિશન વિશે માહિતી આપશો..."
                  className="btn btn-primary fw-bold rounded-5 px-5 pb-2"
                >
                  વોટ્સએપ મેસેજ કરો
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="text-left mb-4">
            <h2 className="fw-bold">NMMS, જ્ઞાન સાધના & નવોદય પરીક્ષા મિશન </h2>
          </div>
          <div className="d-flex">
            <div className="row bg-white py-5 mx-5 rounded-5">
              <div>
                <div className="text-center">
                  <h3>દીક્ષિત પ્રજાપતિ</h3>
                </div>
              </div>
              <div className="text-center mt-3">
                <a
                  href="tel:+919099306894"
                  className="btn btn-primary fw-bold rounded-5 px-5 pb-2"
                >
                  કોલ કરો
                </a>
              </div>
              <div className="text-center mt-3">
                <a
                  href="https://api.whatsapp.com/send?phone=919099306894&text=હું આ વિશે પૂછવા માંગુ છું.."
                  className="btn btn-primary fw-bold rounded-5 px-5 pb-2"
                >
                  વોટ્સએપ મેસેજ કરો
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact end */}

      <Footer />
    </>
  );
}

export default User;
