import { async } from 'q';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [inpval, setInpval] = useState({
    email: "",
    password: "",
});

const setVal = (e) => {
    const { name, value } = e.target;

    setInpval(() => {
        return {
            ...inpval,
            [name]: value
        }
    })
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = inpval;

    const data = await fetch(`${API_BASE_URL}/api/v1/login`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email, password
      })
    });

    const res = await data.json();
   
    if(res.status === 201){
      localStorage.setItem("usersdatatoken",res.result.token);
      navigate("/dashboard");
      setInpval({...inpval,email:"",password:""});
    } else{
      alert("You have entered an invalid username or password!");
    }
     
  };

return (
  <>
    <div className="container mt-5 bg-light p-4 rounded-top">
      <h1 className="text-center text-primary">Edutor App</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            name="email"
            value={inpval.email}
            onChange={setVal}
            className="form-control"
            placeholder="Enter email"
            required
          />

        </div>
        <div className="form-group mt-2">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            name="password"
            value={inpval.password}
            onChange={setVal}
            placeholder="Password"
            className="form-control"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-2">
          Log In
        </button>
      </form>
    </div>
  </>
)
}

export default Login