import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import helmet from "helmet";
// all img
import Arrow from "../../assets/img/Arrow-icon.png";
import Dtm_1 from "../../assets/img/Dtm_1.png";
import Dtm_2 from "../../assets/img/Dtm_2.png";
import Dtm_3 from "../../assets/img/Dtm_3.png";
import Dtm_4 from "../../assets/img/Dtm_4.png";
import Dtm_5 from "../../assets/img/Dtm_5.png";
import Dtm_6 from "../../assets/img/Dtm_6.png";
import Dtm_7 from "../../assets/img/Dtm_7.png";
import Dtm_8 from "../../assets/img/Dtm_8.png";
import Dtm_9 from "../../assets/img/Dtm_9.png";
import DTM_12 from "../../assets/img/DTM/DTM_12.png";
import DTM_13 from "../../assets/img/DTM/DTM_13.png";
import DTM_14 from "../../assets/img/DTM/DTM_14.png";
import DTM_15 from "../../assets/img/DTM/DTM_15.png";
import DTM_16 from "../../assets/img/DTM/DTM_16.png";
import DTM_17 from "../../assets/img/DTM/DTM_17.png";

import contact_icon_1 from "../../assets/img/User/contact_icon_1.png"
import contact_icon_2 from "../../assets/img/User/contact_icon_2.png"
function Dtmhome() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [teams, setUsers] = useState([]);
  const [render, setRender] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getteam`);
      setUsers(res.data);
      updateCount(res.data, selectedOptions);
      setIsLoading(false);
    };
    fetchData();
  }, [render]);

  useEffect(() => {
    updateCount(teams, selectedOptions);
  }, [selectedOptions]);

  //Filter STD
  const handleOptionChange = (event) => {
    const options = Array.from(event.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setSelectedOptions(options);
  };

  const filterStd = () => {
    if (selectedOptions.length === 0) {
      return teams;
    } else {
      return teams.filter((team) => {
        return selectedOptions.some(
          (option) => team.teamstd && team.teamstd.includes(option)
        );
      });
    }
  };

  const updateCount = (data, options) => {
    if (options.length === 0) {
      setCount(data.length);
    } else {
      setCount(filterStd().length);
    }
  };

  useEffect(() => {
    updateCount(teams, selectedOptions);
  }, []);

  //View More Team
  const [displayCount, setDisplayCount] = useState(6);
  const handleViewMore = () => {
    setDisplayCount(displayCount + 6); // Increase the display count by 5
  };

  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? filterStd().length : 6;

  const toggleShowAll = () => {
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    const middlePosition = Math.floor(
      document.body.scrollHeight / 6 - viewportHeight / 1
    );

    window.scrollTo(0, middlePosition);
    setShowAll(!showAll);
  };

  useEffect(() => {
    document.title = "Digital Tecaher Mission - Edutor App";
  }, []);

  return (
    <>
      <Header />
      <helmet>
        <meta
          property="og:title"
          content="Digital Tecaher Mission - Edutor App"
        />
      </helmet>

      <div className="container dtm">
        <div className="row d-flex align-items-center flex-row-reverse">
          <div className="col-md-6 dtm_hero_img">
            <img src={Dtm_1} alt="Dtm_1" />
          </div>

          <div className="col-md-6 dtm_hero">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              <b>ડિજિટલ</b>{" "}
              <span id="content1">
                <b>શિક્ષક</b>
              </span>{" "}
              <span id="content1">
                <b>મિશન</b>
              </span>
            </h1>
            <p className="All_paragraph" style={{ color: "#2577e0" }}>
              રાજ્યભરનાં શ્રેષ્ઠ શિક્ષકોથી બનેલ <br />{" "}
              <span id="content2"> 200+ ટીમો દ્વારા તૈયાર ડિજિટલ કન્ટેન્ટ</span>
            </p>
            <div className="dtmbtn d-grid">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScXxJC6_X3doGucqiMsCrvSRhDGMhvCztngPzVzwtGBK7jjEw/viewform?embedded=true"
                className="d-flex fw-bold flex-nowrap justify-content-center align-items-center btn btn-primary dtm_btn_arrow_1"
              >
                ડિજિટલ શિક્ષક બનવા જોડાઓ
                <img src={Arrow} alt="Arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="dtm" className="container dtm">
        <div className="row">
          <div className="col form_count">
            <h1 className="fw-bold" style={{ color: "#2577e0" }}>
              ટીમ (<span id="teamCount">{count}</span>)
            </h1>
          </div>
          <div className="col-md-6">
            <select
              className="form-select form_select"
              value={selectedOptions}
              onChange={handleOptionChange}
            >
              <option value="ર">ધોરણ પસંદ કરો</option>
              <option value="ધોરણ 5">ધોરણ 5</option>
              <option value="ધોરણ 6">ધોરણ 6</option>
              <option value="ધોરણ 7">ધોરણ 7</option>
              <option value="ધોરણ 8">ધોરણ 8</option>
              <option value="ધોરણ 9">ધોરણ 9</option>
              <option value="ધોરણ 10">ધોરણ 10</option>
              <option value="ધોરણ 11">ધોરણ 11</option>
              <option value="ધોરણ 12">ધોરણ 12</option>
              <option value="જનરલ ટીમ">જનરલ ટીમ</option>
            </select>
          </div>
        </div>

        <div className="row my-5">
          <div className="col">
            <div className="d-flex flex-wrap justify-content-center dtm_team">
              {isLoading ? (
                // <div class="spinner-border m-5 text-primary" role="status">
                //   <span class="visually-hidden">Loading...</span>
                // </div>

                <img src="https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700" style={{ width: '300px' }} />
              ) : filterStd().length === 0 ? (
                <img src="https://www.rajasthanndaacademy.com/assets/images/no-record-found.png" style={{ width: '400px' }} />
              ) : (
                filterStd()
                  .sort(() => Math.random() - 0.5)
                  .slice(0, itemsToShow)
                  .map((team, index) => (
                    <Link
                      to={`/dtm/my-team/${team._id}`}
                      className="text-black"
                      key={`${index}-${team._id}`}
                    >
                      <img
                        className="dtm_teamimg"
                        src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/DTM/TeamPhoto/${team.teamphoto}`}
                        alt="Team-Profile"
                        loading="lazy"
                      />

                      <p
                        className="fw-bold mt-3 mb-0 All_paragraph"
                        style={{ color: "#2577e0" }}
                      >
                        {team.teamstd}
                      </p>
                      <h3 className="fw-bold h1 my-2">{team.teamname}</h3>
                      <p className="All_paragraph mb-0">
                        ટીમનું કુલ કન્ટેન્ટ - {team.addcontent.length}
                      </p>
                    </Link>
                  ))
              )}
            </div>
          </div>
        </div>

        <div className="row my-5 text-center">
          <div className="col">
            <div id="showMoreBtnWrapper">
              {!showAll && (
                <button
                  className="btn btn-primary dtm_btn_clr"
                  id="showMoreBtn"
                  onClick={toggleShowAll}
                >
                  તમામ ટીમો જુઓ
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* statics */}

      <div className="container dtm_container dtm_statics">
        <div className="row d-flex justify-content-center">
          <h1
            className="fw-bold mt-5 mb-4 text-center"
            style={{ color: "#2577e0" }}
          >
            ડિજિટલ શિક્ષક મિશન શું છે ?
          </h1>
          <p className="text-center mt-3 mb-0" style={{ color: "#2577e0" }}>
            શિક્ષકોને ડિજિટલી સશક્ત કરવા અત્યાર સુધીનું સૌથી મોટું મિશન : સમગ્ર
            દેશમાં <a id="content1"></a> પહેલી વાર હજારો શિક્ષકો ટીમ સ્વરૂપે
            બનાવશે ડિજિટલ કન્ટેન્ટ
          </p>
          <img src={Dtm_2} alt="Dtm_2" />
        </div>
        <div className="col">
          <h2
            className="fw-bold mt-5 mb-4 text-center"
            style={{ color: "#2577e0" }}
          >
            અમે દૃઢપણે માનીએ છીએ કે
          </h2>
          <div className="row flex-wrap align-items-center dtm_statics_img_1">
            <div className="col statics_paragraph">
              <p className="top">
                દરેક શિક્ષકનું ડિજિટલ કન્ટેન્ટ હોવું એ વર્તમાન સમયની સૌથી મોટી
                આવશ્યકતા છે
              </p>
              <img src={Dtm_3} alt="Dtm_3" />
            </div>
            <div className="col statics_paragraph">
              <p className="top">
                ડિજિટલ કન્ટેન્ટ શિક્ષકનાં કાર્યની આગવી 'ડિજિટલ ઓળખાણ' ઉભી કરે છે
              </p>
              <img src={Dtm_4} alt="Dtm_4" />
            </div>
            <div className="col statics_paragraph">
              <p>
                શિક્ષક જ્યારે બીજા પ્રતિભાશાળી શિક્ષકો સાથે ટીમ સ્વરૂપે જોડાઈને
                કાર્ય કરે છે તો તે ઘણું અસરકારક રહે છે
              </p>
              <img src={Dtm_5} alt="Dtm_5" />
            </div>
          </div>
          <h3 className="fw-bold mb-4 text-center" style={{ color: "#2577e0" }}>
            એકદમ સરળતાથી <a id="content3"></a>ઓછામાં ઓછાં સમય{" "}
            <a id="content3"></a>ઓછામાં ઓછી મહેનતે
          </h3>
          <p className="All_paragraph text-center mt-5 mb-0">
            શિક્ષક ડિજિટલ કન્ટેન્ટ બનાવતાં થાય તેવાં ઉદ્દેશથી એડ્યુટર એપ દ્વારા
            ડિજિટલ શિક્ષક મિશન ચાલુ કરવામાં આવેલ છે.
          </p>
        </div>

        <div className="row text-center my-4">
          <div className="col">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScXxJC6_X3doGucqiMsCrvSRhDGMhvCztngPzVzwtGBK7jjEw/viewform?embedded=true"
              className="btn"
            >
              <div className="d-flex fw-bold align-items-center dtm_btn_arrow_2 mb-4">
                ડિજિટલ શિક્ષક બનવા જોડાઓ
                <img src={Arrow} alt="Arrow" />
              </div>
            </a>
          </div>
        </div>

        <div className="container">
          <div className="col text-center" style={{ color: "#2577e0" }}>
            <h1 className="fw-bold">ડિજિટલ શિક્ષક મિશન કેવી રીતે કામ કરશે ?</h1>
          </div>
          <div id="howtoworkdtm" className="d-grid my-4">
            <div>
              <img src={DTM_12} alt="Arrow" />
            </div>
            <div>
              <img src={DTM_13} alt="Arrow" />
            </div>
            <div>
              <img src={DTM_14} alt="Arrow" />
            </div>
            <div>
              <img src={DTM_15} alt="Arrow" />
            </div>
            <div>
              <img src={DTM_16} alt="Arrow" />
            </div>
            <div>
              <img src={DTM_17} alt="Arrow" />
            </div>
          </div>
        </div>

        <div className="row text-center my-4" style={{ color: "#2577e0" }}>
          <div className="col">
            <h1 className="fw-bold">
              ડિજિટલ શિક્ષક મિશનમાં કેમ જોડાવું જોઈએ ?
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col mb-3 text-center dtm_statics_img_2">
            <img src={Dtm_6} alt="Dtm_6" className="img-fluid" />
            <img src={Dtm_7} alt="Dtm_7" className="img-fluid" />
            <img src={Dtm_8} alt="Dtm_8" className="img-fluid" />
            <img src={Dtm_9} alt="Dtm_9" className="img-fluid" />
          </div>
        </div>
        <div className="row text-center mt-0 mb-5">
          <div className="col">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScXxJC6_X3doGucqiMsCrvSRhDGMhvCztngPzVzwtGBK7jjEw/viewform?embedded=true"
              className="btn"
            >
              <div className="d-flex fw-bold align-items-center dtm_btn_arrow_2">
                ડિજિટલ શિક્ષક બનવા જોડાઓ
                <img src={Arrow} alt="Arrow" />
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* statics end */}


      {/* contact */}

      <div id="contact_container" className="container">
        <div className="d-flex align-items-center justify-content-center align-content-center">
          <div>
            <img src={contact_icon_1} alt="contact_icon_1" className="img-fluid" />
          </div>
          <div>
            <h1 className="fw-bold text-primary mx-5">મદદ માટે સંપર્ક કરો</h1>
          </div>
          <div>
            <img src={contact_icon_2} alt="contact_icon_2" className="img-fluid" />
          </div>
        </div>
      </div>

      <div id="contact_container" className="container mb-5 d-grid">
        <div>
          <div className="text-left mb-4">
            <h2 className="fw-bold">ડિજિટલ શિક્ષક મિશન</h2>
          </div>
          <div className="d-flex">
            <div className="row bg-white py-5 mx-5 rounded-5">
              <div>
                <div className="text-center">
                  <h3>દલપત કુમાર</h3>
                </div>
              </div>
              <div className="text-center mt-3">
                <Link to="tel:+918866206190" class="btn btn-primary fw-bold rounded-5 px-5 pb-2">કોલ કરો</Link>
              </div>
              <div className="text-center mt-3">
                <Link to="https://api.whatsapp.com/send?phone=918866206190&text=ડિજિટલ શિક્ષક મિશન વિશે માહિતી આપશો..." class="btn btn-primary fw-bold rounded-5 px-5 pb-2">વોટ્સએપ મેસેજ કરો</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact end */}
      
      <Footer />
    </>
  );
}

export default Dtmhome;
