import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
 

const CreateTeam = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  //Single Team Name 
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/team/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      });
      const { teamname, teamstd } = res.data;
      setTeamname(teamname);
      setTeamstd(teamstd);
    };
    fetchData();
  }, [render]);

  const [teamname, setTeamname] = useState('');
  const [teamstd, setTeamstd] = useState('');

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${API_BASE_URL}/api/updateteam/${id}`, { teamname, teamstd });
      const data = response.data
      alert("Team Updated!");
      navigate("/dtm-admin");

    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit DTM Team Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Edit DTM Team Profile</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-2">
              {/* Vertical Form */}
              <h5>Team Details</h5>
              <form method="post" encType="multipart/form-data" onSubmit={handleUpdate} >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Team name:
                  </label>
                  <input
                    type="text"
                    value={teamname}
                    onChange={(e) => setTeamname(e.target.value)}
                    className="form-control"
                    placeholder="Name"
                    required
                  />

                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Std:
                  </label>
                  <input
                    type="text"
                    value={teamstd}
                    onChange={(e) => setTeamstd(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Std"
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Update Team
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateTeam;
