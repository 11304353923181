import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./components/Blogheader";
import Footer from "../../components/Footer";

// all img
import Blog_1 from "../../assets/img/Blog/blog_1.png";

// css
import "../../assets/css/blog.css";

// Skeleton component

const Skeleton = () => {
  return (
    <div className="col">
      <div className="wrapper">
        <div className="mx-4 mt-4 skeleton-wrapper">
          {/* Adjust the size of the skeleton image */}
          <div className="skeleton-image skeleton-border" style={{ width: "110%", height: "100%" }}></div>
        </div>
        <div className="p-4">
          {/* Adjust the size of the skeleton title and author */}
          <h1 className="h4 fw-bold text-dark rounded-1 skeleton-text" style={{ width: "80%", height: "20px" }}></h1>
          <h2 className="d-flex mt-3">
            <p className="h6 text-dark rounded-2 skeleton-text" style={{ width: "40%", height: "15px" }}></p>
            <p className="h6 rounded" style={{ color: "#EB5885", width: "20%", height: "15px" }}></p>
          </h2>
        </div>
      </div>
    </div>
  );
};

function Bloghome() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    document.title = "Bloghome - Edutor App";
  }, []);

  const [blog, setBlog] = useState([]);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/get-blog`);
      setBlog(res.data);
      setIsLoading(false);
    };
    fetchData();
  }, [render]);

  const [category, setCategory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getcategory`);
      setCategory(res.data);
    };
    fetchData();
  }, []);

  //Colour Code
  const bgcolour1 = "#2fc7b522";
  const bgcolour2 = "#4C00FF1A";
  const bgcolour3 = "#e8a23a34";
  const bgcolour4 = "#FF00AE1B";
  const bgcolour5 = "#64C72F";
  const bgcolour6 = "#DB6F6F";
  const bgcolour7 = "#3AAFE8";
  const bgcolour8 = "#F4D550";

  const bgcolours = [
    bgcolour1,
    bgcolour2,
    bgcolour3,
    bgcolour4,
    bgcolour5,
    bgcolour6,
    bgcolour7,
    bgcolour8
  ];

  return (
    <>
      <Header />
      <div id="blog-container_1" className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col">
            <img src={Blog_1} alt="Blog_1" />
          </div>
          <div className="col user_hero">
            <h1 className="fw-bold mb-4" style={{ color: "#EB5885" }}>
              એડ્યુટર જીજ્ઞાસા
            </h1>
            <p className="h3">
              વર્તમાન સમયમાં અતિ મહત્વનાં વિષયોનું શિક્ષકો અને વિદ્યાર્થીઓમાં
              જ્ઞાન અને સમજણ વધે તે હેતુથી એકદમ સરળ ભાષામાં સમજાવતા લેખો
            </p>
          </div>
        </div>
      </div>
      <div id="blog" className="container">
        <div className="row gap-5">
          {isLoading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} />
            ))
          ) : (
            blog
              .slice(-3)
              .reverse()
              .map((blogItem, index) => (
                <div className="col" key={index}>
                  <Link to={`/blog/post/${blogItem._id}`}>
                    <div className="wrapper">
                      <div className="mx-4 mt-4">
                        <img
                          id="blog-img"
                          src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Blog/Images/${blogItem.images}`}
                          className="img-fluid"
                          alt={`Blog Image ${index}`}
                        />
                      </div>

                      <div className="p-4">
                        <h1 className="h4 fw-bold text-dark">
                          {blogItem.tittle}
                        </h1>
                        <h2 className="d-flex mt-3">
                          <p className="h6 text-dark">{blogItem.author}</p>
                          <span className="h6 text-dark">.</span>
                          <p className="h6" style={{ color: "#EB5885" }}>
                            {blogItem.minute}
                          </p>
                          {/* <span className="h6 text-dark">.</span>
                          <i className="bi h6 bi-hand-thumbs-up text-dark"></i>
                          <p className="h6 text-dark">231 Likes</p> */}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          )}
        </div>
      </div>

      <div id="blog-container_2" className="container">
        <div className="row my-5">
          <h1 className="fw-bold">ટોપિક પ્રમાણે વાંચો</h1>
          <div id="All_topic" className="d-grid mt-5 gap-4">
            {category
              .slice()
              .reverse()
              .map((categoryItem, index) => {
                const iconIndex = index % bgcolours.length;
                const bgcolour = bgcolours[iconIndex];
                return (
                  <div
                    id="topic_1"
                    className="col"
                    key={categoryItem._id}
                    style={{ backgroundColor: bgcolour }}
                  >
                    <Link to={`/blogcategory/${categoryItem._id}`}>
                      <div id="topic_img" className="p-3 pt-4">
                        <img
                          src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Blog/Category/Icon/${categoryItem.categoryicon}`}
                          alt={`Blog Icon ${index}`}
                          className="img-fluid"
                          width="120"
                        />
                      </div>
                      <div id="topic_detail" className="pb-3">
                        <h1 className="text-dark px-3 m-0 fw-bold">
                          {categoryItem.categoryname}
                        </h1>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Bloghome;
