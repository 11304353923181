import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Footer from "../../components/Footer";

const Skeleton = () => {
  return (
    <div className="col">
      <div className="wrapper">
        <div id="skeleton-wrapper" className="skeleton-wrapper">
          {/* Adjust the size of the skeleton image */}
          <div id="skeleton-image" className="skeleton-image skeleton-border" style={{ width: "475%", height: "250%" }}></div>
        </div>
        <div className="p-4">
          {/* Adjust the size of the skeleton title and author */}
          <h1 className="h4 fw-bold text-dark rounded-1 skeleton-text" style={{ width: "80%", height: "20px" }}></h1>
          <h2 className="d-flex mt-3">
            <p className="h6 text-dark rounded-2 skeleton-text" style={{ width: "40%", height: "15px" }}></p>
            <p className="h6 rounded" style={{ color: "#EB5885", width: "20%", height: "15px" }}></p>
          </h2>
        </div>
      </div>
    </div>
  );
};

function Blogcontent() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [team, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/blog/${id}`);
      const data = setUsers(res.data);
      setIsLoading(false);
      console.log(data);
    };
    fetchData();
  }, [render]);
  return (
    <>
      <>
        {isLoading ? (
          Array.from({ length: 1 }).map((_, index) => (
            <Skeleton key={index} />
          ))
        ) : (
          team && (
            <div id="blog_content">
              <div id="blog_content_img">
                <img
                  src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/Blog/Poster/${team.poster}`}
                  alt=""
                />
              </div>
              <div className="image-shadow"></div>
              <div className="col mx-5">
                <div className="d-flex justify-content-between">
                  <h1>{team.tittle}</h1>
                  {/* <p className="d-flex flex-column gap-0 align-items-center mx-5 mb-0">
              <i className="bi h3 bi-hand-thumbs-up"></i>
              231 Likes
            </p> */}
                </div>
                <h2 className="d-flex mt-3 align-items-end gap-2">
                  <p className="h3 mb-0">{team.author}</p>
                  <span>.</span>
                  <p className="h3 mb-0" style={{ color: "#EB5885" }}>
                    {team.minute}
                  </p>
                </h2>
              </div>
            </div>
          )
        )}
      </>

      <div id="blog_content_details" className="container">
        <div className="row">
          <div className="col">
            {team && (
              <pre id="prediv" className="mb-0">
                {team.content}
              </pre>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogcontent;
