import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import API_BASE_URL from "../../../api";

const CreateBlog = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [tittle, setTittle] = useState("");
  const [category, setCategory] = useState("");
  const [author, setAuthor] = useState("");
  const [content, seContent] = useState("");
  const [minute, setMinute] = useState("");
  const [poster, setPoster] = useState("");

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  const setimgfile = async (e) => {
    setPoster(e.target.files[0]);
  };

  const [images, setFileTwo] = useState(null);
  const handleFileTwoChange = (event) => {
    setFileTwo(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("tittle", tittle);
    formData.append("category", category);
    formData.append("author", author);
    formData.append("content", content);
    formData.append("minute", minute);
    formData.append("poster", poster);
    formData.append("images", images);

    
    try {
      await axios.post(`${API_BASE_URL}/api/create-blog`,formData);      
      alert("New Blog Added!");
      navigate("/blog-main");
    } catch (error) {
      console.log("Error creating new blog:", error.message);
    }
  };

  const [showcategory, setshowcategory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getcategory`);
      setshowcategory(res.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create New Blog</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">Blog</li>
              <li className="breadcrumb-item active">Create New Blog</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-2">
              {/* Vertical Form */}
              <h5>Blog Details</h5>
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Blog Tittle:
                  </label>
                  <input
                    type="text"
                    value={tittle}
                    name="tittle"
                    onChange={(e) => setTittle(e.target.value)}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Enter Blog Category:
                  </label>
                  <select
                    className="form-control mt-1"
                    value={category}
                    name="category"
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {showcategory.map((ele, i) => (
                    <option value={ele.categoryname}>{ele.categoryname}</option>
                    ))}
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter author name:
                  </label>
                  <input
                    type="text"
                    value={author}
                    name="author"
                    onChange={(e) => setAuthor(e.target.value)}
                    className="form-control"
                    placeholder="Author name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Content:
                  </label>
                  <textarea
                    type="text"
                    value={content}
                    name="content"
                    onChange={(e) => seContent(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Write your content..."
                    required
                    style={{ height: 400 }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter minute of reading this blog:
                  </label>
                  <input
                    type="text"
                    value={minute}
                    name="minute"
                    onChange={(e) => setMinute(e.target.value)}
                    className="form-control"
                    placeholder="Minute of reading  blog"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Blog Poster:
                  </label>
                  <input
                    name="poster"
                    type="file"
                    onChange={setimgfile}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>


                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Blog Thumbnail:
                  </label>
                  <input
                    type="file"
                    name="images"
                    onChange={handleFileTwoChange}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Upload Blog
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateBlog;
