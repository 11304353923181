import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import API_BASE_URL from "../../../../api"

const TeamInfo = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [user, setUsers] = useState(null);
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const { obid } = useParams();


  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  //Single Team Name
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${API_BASE_URL}/api/single/teacher/${id}/${obid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = res.data;
      setTeachername(data[0].teacherdetail[0].teachername);
      setSchoolname(data[0].teacherdetail[0].schoolname);
      setSubject(data[0].teacherdetail[0].subject);
      setDay(data[0].teacherdetail[0].day);
      setteacherprofilelink(data[0].teacherdetail[0].teacherprofilelink);
    };
    fetchData();
  }, []);

  //Teacher Details
  const [teachername, setTeachername] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [subject, setSubject] = useState("");
  const [day, setDay] = useState("");
  const [teacherprofilelink, setteacherprofilelink] = useState("");
  const [teacherphoto, setFile] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_BASE_URL}/api/updateteacher/${id}/${obid}`, {
        teachername,
        schoolname,
        subject,
        day,
        teacherprofilelink,
      });
      navigate(`/dtm-admin`);
      alert("Teacher Profile Updated!");
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Teacher Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">DTM</li>
              <li className="breadcrumb-item active">Edit Teacher Profile</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            {/* Teacher */}
            <div className="card-body mt-1">
              {/* Vertical Form */}
              <h5>Teacher details</h5>
              <form
                method="post"
                encType="multipart/form-data"
                onSubmit={handleUpdate}
              >
                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher name:
                  </label>
                  <input
                    name="teachername"
                    type="text"
                    value={teachername}
                    onChange={(e) => setTeachername(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School name:
                  </label>
                  <input
                    name="schoolname"
                    type="text"
                    value={schoolname}
                    onChange={(e) => setSchoolname(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="School name"
                    required
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">
                    Quiz Subject
                  </label>
                  <select
                    className="form-control"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option >Select Subject</option>
                    <option value="ગણિત">ગણિત</option>
                    <option value="ગુજરાતી">ગુજરાતી</option>
                    <option value="સામાજિક વિજ્ઞાન">સામાજિક વિજ્ઞાન </option>
                    <option value="અંગ્રેજી">અંગ્રેજી</option>
                    <option value="વિજ્ઞાન">વિજ્ઞાન </option>
                    <option value="હિન્દી">હિન્દી</option>
                    <option value="આસપાસ">આસપાસ</option>
                    <option value="અર્થશાસ્ત્ર">અર્થશાસ્ત્ર</option>
                    <option value="સમાજશાસ્ત્ર">સમાજશાસ્ત્ર</option>
                    <option value="તત્વજ્ઞાન">તત્વજ્ઞાન </option>
                    <option value="ભૂગોળ">ભૂગોળ</option>
                    <option value="મનોવિજ્ઞાન">મનોવિજ્ઞાન</option>
                    <option value="વાણિજ્ય અને વ્યવસ્થા">
                      વાણિજ્ય અને વ્યવસ્થા
                    </option>
                    <option value="નામાનાં મૂળતત્વો">નામાનાં મૂળતત્વો</option>
                    <option value="આંકડાશાસ્ત્ર">આંકડાશાસ્ત્ર</option>
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="exampleFormControlSelect1">Day</label>
                  <select
                    className="form-control"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  >
                    <option selected value="">Select Day</option>
                    <option value="સોમવાર">સોમવાર</option>
                    <option value="મંગળવાર">મંગળવાર</option>
                    <option value="બુધવાર">બુધવાર</option>
                    <option value="ગુરુવાર">ગુરુવાર</option>
                    <option value="શુક્રવાર">શુક્રવાર</option>
                    <option value="શનિવાર">શનિવાર</option>
                  </select>
                </div>

                <div className="col-12 mt-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher Profile Link:
                  </label>
                  <input
                    name="teacherprofilelink"
                    type="url"
                    value={teacherprofilelink}
                    onChange={(e) => setteacherprofilelink(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Profile Link"
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Update Teacher
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TeamInfo;
