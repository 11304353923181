import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";

// all img
import Arrow from "../../assets/img/Arrow-icon.png";
import Pop_up from "../../assets/img/pop_up_bg.png";
import icon1 from "../../assets/img/all_content_icon_1.png";
import icon2 from "../../assets/img/all_content_icon_2.png";
import icon3 from "../../assets/img/all_content_icon_3.png";
import icon4 from "../../assets/img/all_content_icon_4.png";

import DTM_18 from "../../assets/img/DTM/DTM_18.jpg"
import DTM_19 from "../../assets/img/DTM/DTM_19.png"
import DTM_20 from "../../assets/img/DTM/DTM_20.png"

// pop up css
import "../../assets/css/popup.css"

function Dtmteam(data) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const [team, setUsers] = useState(null);
  const [render, setRender] = useState(false);

  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/team/${id}`);
      setUsers(res.data);
    };
    fetchData();
  }, [render]);

  //std select
  const [teaminfo, setarr] = useState([]);
  const [teaminfoCount, setteaminfoCount] = useState([]);
  const [getuserdata, setuserdata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteaminfo/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      window.alert("error in get data");
    } else {
      setuserdata(data);
      setarr(data.teaminfo);
      setteaminfoCount(data.teaminfo.length);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //! Get Content API
  const [content, setcontent] = useState([]);
  const [getcontent, setcontentdata] = useState([]);

  const GetContent = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getcontent/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    // console.log(data);
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setcontentdata(data);
      setcontent(data.addcontent);
    }
  };
  useEffect(() => {
    GetContent();
  }, []);

  //teacher list select
  const [teamteacher, setteacher] = useState([]);
  const [getteacherdata, setteacherdata] = useState([]);

  const GetTeacher = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getteacher/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    // console.log(data);
    if (!data) {
      window.alert("Please contact admin!");
    } else {
      setteacherdata(data);
      setteacher(data.teacherdetail);
    }
  };
  useEffect(() => {
    GetTeacher();
  }, []);

  //Pop up std select
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Filter Std
  const handleOptionClick = (option) => {
    const options = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(options);
  };

  const filterItems = () => {
    if (selectedOptions.length === 0) {
      return content;
    } else {
      return content.filter((item) => {
        return selectedOptions.some((option) => item.std.includes(option));
      });
    }
  };
  //std
  const filterTeamInfo = () => {
    if (selectedOptions.length === 0) {
      return teaminfo;
    } else {
      return teaminfo.filter((item) => {
        return selectedOptions.some((option) => item.std.includes(option));
      });
    }
  };

  const [selectedSubject, setSelectedSubject] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState([]);

  //Filter Content Subject
  const handleOptionSubject = (event) => {
    const options = Array.from(event.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setSelectedSubject(options);
  };

  const filterSubject = () => {
    if (selectedSubject.length === 0) {
      return filterItems();
    } else {
      return filterItems().filter((team) => {
        return selectedSubject.some((option) => {
          return team.subject.toLowerCase() === option.toLowerCase();
        });
      });
    }
  };

  //navigate
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/digital-teacher-mission");
  };

  const icons = [icon1, icon2, icon3, icon4];
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (team) {
      document.title = `${team.teamname} - Edutor App`;
    }
  }, [team]);

  const [displayCount, setDisplayCount] = useState(6);
  const handleViewMore = () => {
    setDisplayCount(displayCount + 6);
  };

  //Content Type
  const [selectedContent, setSelectedContent] = useState("Quiz");

  const handleSelectedContent = (contenttype) => {
    setSelectedContent(contenttype);
    const Quiz = document.getElementById("Quiz");
    const Magictest = document.getElementById("Magictest");
    const Vacationutsav = document.getElementById("Vacationutsav");
  };

  useEffect(() => {
    setSelectedContent("Quiz");
  }, []);

  const filteredContent = selectedContent
    ? filterSubject().filter((item) => item.contenttype === selectedContent)
    : filterSubject();

  //Get Pop-Up
  const [popup, setpopup] = useState([]);

  const GetPopup = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getpopup/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      if (data.popup.length > 0) {
        setpopup(data.popup);
        setTargetDateTime(data.popup[0].time);
      }
    }
  };
  useEffect(() => {
    GetPopup();
  }, []);

  //Magic test count-down
  const [targetDateTime, setTargetDateTime] = useState();
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [countdownExpired, setCountdownExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = new Date(targetDateTime) - now;

      if (timeDifference <= 0) {
        clearInterval(interval);
        setRemainingTime(0);
        setCountdownExpired(true);
      } else {
        setRemainingTime(timeDifference);
        setCurrentDateTime(now);
        setCountdownExpired(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDateTime]);

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);


  const popupContent = document.querySelector('#popup .bg-cover');
  if (popupContent) {
    setTimeout(() => {
      popupContent.style.transform = 'scale(1)';
    }, 100);
  }

  const closePopup = () => {
    const popup = document.getElementById('popup');
    popup.style.opacity = '0';
    setTimeout(() => popup.style.display = 'none', 300);
  };

  return (
    <>
      <div>
        {teaminfoCount >= 2 ? (
          <>
            <div
              className="overlay"
              style={{ opacity: showPopup ? 0.3 : 1 }}
            ></div>
            <Modal
              className="mow"
              size="lg"
              show={showPopup}
              onHide={() => setShowPopup(false)}
              backdrop="static"
              keyboard={false}
            >
              <img
                id="img-fluid"
                className="background-image"
                src={Pop_up}
                alt="Pop_up"
              />
              <Modal.Body>
                <p
                  className="btn px-0 mt-4 mb-5"
                  variant="secondary"
                  onClick={() => handleButtonClick()}
                >
                  <i id="left_arrow" className="bg-light bi-arrow-left"></i>
                </p>
                <h1 className="h1 fw-bold text-light text-center">
                  તમારું ધોરણ પસંદ કરો
                </h1>
                <div
                  id="std"
                  className="row d-flex my-5 gap-3 justify-content-center align-content-center text-center"
                  style={{ margin: "0rem 2rem" }}
                >
                  {teaminfo.map((teaminfo, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        handleOptionClick(`${teaminfo.std}`);
                        setShowPopup(false);
                      }}
                      className={`btn btn-light pop_btn ${selectedOptions.includes(teaminfo.std)
                        ? "selected"
                        : `${teaminfo.std}`
                        }`}
                    >
                      {teaminfo.std}
                    </button>
                  ))}
                </div>
                <p
                  className="text-center text-dark"
                  style={{ margin: "0rem 2rem" }}
                >
                  આ ટીમ દ્વારા એક કરતાં વધુ ધોરણમાં કન્ટેન્ટ બનાવવામાં આવેલ છે.
                </p>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </div>

      {/* Magic test Pop-Up */}
      {filterTeamInfo().map((teaminfo, i) => (
        <div key={i}>
          {popup.map(
            (popup, j) =>
              popup.std === teaminfo.std && (
                <div key={j}>
                  <div id="popup" className="pop-fixed pop-inset-0 pop-flex pop-items-center pop-justify-center pop-z-50 pop-bg-black pop-bg-opacity-70 pop-transition-opacity">
                    <div className="pop-bg-cover pop-in pop-rounded-lg pop-transform pop-transition-transform pop-scale-0" style={{ backgroundImage: `url(${DTM_18})` }}>
                      <button onClick={closePopup} className="pop-absolute pop-top-4 pop-right-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="pop-w-6 pop-h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>

                      <div className="pop-flex pop-flex-col pop-items-center pt-0 pop-pt-0 pop-p-4 pop-space-y-5">
                        <div className="pop_std fw-bold pop-bg-pink-500 pop-px-10 pop-py-1 pop-text-white pop-mb-5 pop-rounded-br-lg pop-rounded-bl-lg pop-text-xl">
                          {popup.std}
                        </div>
                        <h1 className="pop-text-2xl pop-text-black">સ્વ-મૂલ્યાંકન માટે શ્રેષ્ઠ તક</h1>
                        <h2 className="pop-text-lg h1 fw-bold pop-text-black pop-mb-3">{`${popup.tittle}`}</h2>

                        <div className="mt-1">
                          <div className="pop-bg-white pop-flex pop-items-center pop-p-2 pop-px-4 pop-rounded-xl pop-shadow-md pop-space-x-4">
                            <img src={DTM_19} alt="Teacher Photo" className="pop-w-16 pop-h-16" />
                            <div>
                              <p className="pop-text-gray-700 mb-0 pop-font-semibold">વિષય નિષ્ણાંત શિક્ષક</p>
                              <h1 className="pop-text-black fw-bold mb-0 pop-text-xl">{popup.teachername}</h1>
                            </div>
                          </div>

                          <div className="pop-bg-white mt-3 pop-flex pop-items-center pop-p-2 pop-px-4 pop-rounded-xl pop-shadow-md pop-space-x-4">
                            <img src={DTM_20} alt="Test Image" className="pop-w-16 pop-h-16" />
                            <h1 className="pop-text-black mb-0 fw-bolder pop-text-xl">20 પ્રશ્નો</h1>
                          </div>
                        </div>

                        <p className="pop-text-p fw-bold pop-text-black">સાંજે 7 થી 9 વચ્ચે મેજિક ટેસ્ટ આપી શકાશે</p>
                        {/* <p> {popup.std} <br /> {popup.teachername} <br /> વિષય: {popup.subject} </p> */}

                        <div>
                          {countdownExpired ? (
                            <Link className="pop_btn_dtm" to={`${popup.link}`}>
                              હમણાં જ આપો
                            </Link>
                          ) : (
                            <button className="pop_btn_dtm fw-bold">
                              {days}d {hours}h {minutes}m{" "} {seconds}s
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      ))}



      <Header />
      {team && (
        <div
          id="container_1"
          className="container d-flex flex-wrap justify-content-center align-items-center my-5"
        >
          <div id="row" className="row">
            <div className="col">
              <img
                src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/DTM/TeamPhoto/${team.teamphoto}`}
                loading="lazy"
                alt="Team-Profile"
                className="dtm_teamimg_for_team"
              />
            </div>

            <div id="col" className="col">
              {filterTeamInfo().map((teaminfo, i) => (
                <p
                  className="h3 my-0 fw-bold All_paragraph"
                  style={{ color: "#2577e0" }}
                >
                  {teaminfo.std}
                </p>
              ))}
              <h2 className="my-4 fw-bold h1">{team.teamname}</h2>
              <h3 className="h4 mb-4 text-justify">
                રાજ્યનાં શ્રેષ્ઠ શિક્ષકો દ્વારા બનેલ {team.teamname} તમારાં માટે
                ખાસ બનાવી રહી છે દરરોજ એક પ્રેક્ટીસ ક્વિઝ અને પરીક્ષામાં ઉપયોગી
                મટીરિયલ અમારાં વ્હોટસએપ ગ્રુપમાં જોડાઓ
              </h3>
              {filterTeamInfo().map((teaminfo, i) => (
                <Link
                  to={`${teaminfo.grouplink}`}
                  className="d-flex fw-bold flex-nowrap justify-content-center align-items-center btn btn-primary dtm_btn_arrow_3"
                >
                  અમારાં વ્હોટસએપ ગ્રુપમાં જોડાઓ
                  <img src={Arrow} alt="Arrow" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      <div id="dtm" className="container dtm">
        <div className="col-md-6">
          <select
            className="form-select sub_select"
            value={selectedOptions}
            onChange={handleOptionSubject}
          >
            <option value="">વિષય પસંદ કરો</option>
            {teamteacher.map((teacher, i) => (
              <option value={teacher.subject}>{teacher.subject}</option>
            ))}
          </select>
        </div>
      </div>
      {/* Quiz */}

      <div id="nmms_quiz_year" className="container text-center">
        <button
          id="Quiz"
          className="h3 mx-5 my-5 fw-bold active"
          style={{ color: "#2577e0" }}
          onClick={() => handleSelectedContent("Quiz")}
          active
        >
          ડેઈલી ક્વિઝ
        </button>

        <button
          id="Magictest"
          className="h3 mx-5 my-5 fw-bold active"
          style={{ color: "#2577e0" }}
          onClick={() => handleSelectedContent("Magictest")}
        >
          મેજિક ટેસ્ટ
        </button>

        <button
          id="Vacationutsav"
          className="h3 mx-5 my-5 fw-bold active"
          style={{ color: "#2577e0" }}
          onClick={() => handleSelectedContent("Vacationutsav")}
        >
          વેકેશન ઉત્સવ
        </button>

        <div id="underline" className="underline"></div>
      </div>

      <div className="container justify-content-between accordion dtm_1">
        {filteredContent
          .reverse()
          .slice(0, displayCount)
          .map((ele, i) => {
            const iconIndex = i % icons.length;
            const icon = icons[iconIndex];
            let contentText = "";
            if (selectedContent === "Magictest") {
              contentText = `${ele.day} ટેસ્ટ`;
            } else if (selectedContent === "Vacationutsav") {
              contentText = "પરીક્ષા";
            } else {
              contentText = `${ele.day} ક્વિઝ`;
            }
            return (
              <div
                className="row justify-content-center align-items-center"
                key={`${i}-${ele._id}`}
              >
                <div id="col_1" className="col">
                  <pre className="p-0">
                    {ele.subject}
                    <span className="mx-2">.</span>
                    {contentText}
                  </pre>
                  <h1 className="fw-bold my-3">{ele.topic}</h1>
                  <p>{ele.teachername}</p>
                </div>
                <div id="col_1" className="col">
                  <img src={icon} alt="" />
                </div>
                <div className="col-10 mt-3">
                  <Link
                    to={`${ele.link}`}
                    className="d-flex fw-bold flex-nowrap align-items-center justify-content-center btn dtm_btn_arrow_4"
                  >
                    ક્વિઝ રમો
                    <img src={Arrow} alt="Arrow" />
                  </Link>
                </div>
              </div>
            );
          })}
      </div>

      <div className="row mt-5 text-center">
        <div className="col">
          <div id="showMoreBtnWrapper">
            {filterItems().length > displayCount && (
              <button
                className="btn btn-primary dtm_btn_clr"
                id="showMoreBtn"
                onClick={handleViewMore}
              >
                તમામ ક્વિઝ જુઓ
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Quiz end */}

      {/* Material */}

      {/* <div>
                <img src="https://contents.edutorapp.com/Cohort/asset/img/Ani_1.gif" alt="Animation" width="450" height="450" loop />
            </div> */}

      {/* Material end */}

      {/* Video end */}

      {/* <div>
                <img src="https://contents.edutorapp.com/Cohort/asset/img/Ani_1.gif" alt="Animation" width="450" height="450" loop />
            </div> */}

      {/* Video end */}

      {/* Teacher Profile */}

      <div id="container_2" className="container text-left mt-5 mb-5">
        {team && (
          <h1 className="text-center fw-bold mt-5" style={{ color: "#2577e0" }}>
            {team.teamname}નાં શિક્ષકો
          </h1>
        )}
        <div className="row mt-3 justify-content-evenly">
          {teamteacher.map((ele, i) => (
            <div
              id="col_md"
              className="col-md-6 col-lg-5 mt-5"
              key={`${i}-${ele._id}`}
            >
              <Link to={`${ele.teacherprofilelink}`}>
                <div id="card" className="card">
                  <div
                    id="card-body"
                    className="card-body my-2 px-4 d-flex align-items-center justify-content-center mt-2"
                  >
                    <img
                      className="mr-3"
                      src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/DTM/TeacherPhoto/${ele.teacherphoto}`}
                      alt="Teacher Photo"
                      loading="lazy"
                      width="130px"
                      height="130px"
                    />
                    <div id="card-detail">
                      <h1 className="h2 fw-bold">{ele.teachername}</h1>
                      <p className="h5 mt-2 mb-3">{ele.schoolname}</p>
                      <div className="d-flex justify-content-start gap-3">
                        <p className="card-subtitle">
                          <button className="btn btn-primary user_btn_clr">
                            {ele.subject}
                          </button>
                          <em>{ele.day}</em>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div
        id="container_1"
        className="container d-flex flex-wrap justify-content-center align-items-center mb-4"
        style={{ margin: "-3rem 1rem" }}
      >
        <div id="row" className="row">
          <div className="col">
            {filterTeamInfo().map((teaminfo, i) => (
              <Link
                to={`${teaminfo.grouplink}`}
                className="d-flex fw-bold flex-nowrap justify-content-center align-items-center btn btn-primary dtm_btn_arrow_3"
              >
                અમારાં વ્હોટસએપ ગ્રુપમાં જોડાઓ
                <img src={Arrow} alt="Arrow" />
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* Teacher Profile end */}

      <Footer />
    </>
  );
}

export default Dtmteam;
