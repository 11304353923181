import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const EduMitraPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedStandard, setselectedStandard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState();
  const [subjects, setsubjects] = useState([]);
  const [selectedChapter, setselectedChapter] = useState("");
  const [ChaptersData, setChaptersData] = useState([]);
  const [TopicName, setTopicName] = useState("");
  const [TopicData, setTopicData] = useState([]);
  const [OrderBY, setOrderBY] = useState("");
  const [Isvisible, setIsvisible] = useState();
  const [LoadAgain, setLoadAgain] = useState(false);

  useEffect(() => {
    document.title = "Edit EduMitra | Edutor App";
    FetchSingleEduMitra();
  }, [LoadAgain]);

  const handleStandard = async (value) => {
    await FetchSubjects(value);
    setselectedStandard(value);
    setSelectedSubject(null);
    setselectedChapter(null);
    setChaptersData([]);
  };

  const handleSubject = async (subjectID) => {
    await FetchChaptersData(subjectID);
    setSelectedSubject(subjectID);
  };

  // Subject List
  const FetchSubjects = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/trending/subjects`,
        {
          params: {
            standard_id: value,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setsubjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Fetch Chapter Data
  const FetchChaptersData = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/chapter?subject_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setChaptersData(response.data);
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  function convertToBoolean(str) {
    return str.toLowerCase().trim() === "true";
  }

  // Fetch Single  Data
  const FetchSingleEduMitra = async (value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_API_BASE_URL}/single/edu-mitra/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setTopicData(response.data);
        setOrderBY(response.data.orderby);
        setselectedStandard(response.data.standard_id);
        setSelectedSubject(response.data.subject_id);
        FetchSubjects(response.data.standard_id);
        setselectedChapter(response.data.chapter_id);
        FetchChaptersData(response.data.subject_id);
        setTopicName(response.data.tittle);
        if (response.data.is_visible) {
          setIsvisible(true);
        } else {
          setIsvisible(false);
        }
      }
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  };

  const UpdateEduMitra = async () => {
    setLoadAgain(false);

    if (!selectedStandard || !selectedSubject || !TopicName || !OrderBY) {
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      return;
    }

    try {
      const updateTopicData = {
        id: id,
        board_id: 1,
        medium_id: 1,
        orderby: parseInt(OrderBY),
        standard_id: parseInt(selectedStandard),
        subject_id: parseInt(selectedSubject),
        chapter_id: parseInt(selectedChapter),
        tittle: TopicName,
        is_visible: convertToBoolean(Isvisible),
      };

      const editTopicResponse = await axios.put(
        `${REACT_APP_NODE_API_BASE_URL}/admin/edit/edu-mitra`,
        updateTopicData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (editTopicResponse.data) {
        toast.success(editTopicResponse.data.message, {
          position: "top-center",
        });
        setLoadAgain(true);
        navigate("/upload-edumitra");
      }
    } catch (error) {
      console.error("Error updated topic:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit EduMitra</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item">Edit EduMitra</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Standard:</label>
                  <select
                    className="form-control mt-1"
                    value={selectedStandard}
                    onChange={(e) => handleStandard(e.target.value)}
                  >
                    <option selected value="">
                      Select Standard
                    </option>
                    <option value="1">ધોરણ 12</option>
                    <option value="2">ધોરણ 11</option>
                    <option value="3">ધોરણ 10</option>
                    <option value="4">ધોરણ 9</option>
                    <option value="5">ધોરણ 8</option>
                    <option value="6">ધોરણ 7</option>
                    <option value="7">ધોરણ 6</option>
                    <option value="8">ધોરણ 5</option>
                    <option value="9">ધોરણ 4</option>
                    <option value="10">ધોરણ 3</option>
                    <option value="11">ધોરણ 2</option>
                    <option value="12">ધોરણ 1</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Subjects:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedSubject}
                    onChange={(e) =>
                      handleSubject(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text
                      )
                    }
                  >
                    <option selected>Select subject</option>
                    {subjects.map((subject, index) => (
                      <option key={index} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Chapter:</label>

                  <select
                    className="form-control mt-1"
                    value={selectedChapter}
                    onChange={(e) => setselectedChapter(e.target.value)}
                  >
                    <option selected>Select Chapter</option>
                    {ChaptersData.map((chapter, index) => (
                      <option key={index} value={chapter.chapter_id}>
                        {chapter.chapter_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Order BY:</label>

                  <input
                    type="number"
                    className="form-control"
                    value={OrderBY}
                    onChange={(e) => setOrderBY(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Visible: </label>

                  <select
                    className="form-select"
                    value={Isvisible}
                    onChange={(e) => setIsvisible(e.target.value)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="true">Visible</option>
                    <option value="false">Hidden</option>
                  </select>
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter EduMitra Tittle:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={TopicName}
                    onChange={(e) => setTopicName(e.target.value)}
                    required
                  />
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-info"
                    name="insert"
                    onClick={UpdateEduMitra}
                  >
                    Update EduMitra
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default EduMitraPage;
