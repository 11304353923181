import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/edutor-app-logo.png';
import togal_icon from '../assets/img/togal_icon.png';
import "../assets/css/style.css"

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleItem = () => {
    setSubmenuActive(!submenuActive);
  };

  const closeSubmenu = (e) => {
    if (submenuActive && !e.target.closest('.submenu-active')) {
      setSubmenuActive(false);
    }
  };

  return (
    <>
      <style>{`
                .usernav {
                    padding: 1rem 0rem !important;
                  }

                  .navbar-toggler{
                    border: none;
                  }
                  
                  .navbar-toggler:focus{
                    box-shadow: none;
                  }

                  .container{
                    --bs-gutter-x: 0rem !important; 
                  }
                  
                  .user-nav-img img {
                    width: 150px;
                  }
                  
                  .user-nav-link{
                    background-color: #2577e0 !important;
                    color: white !important;
                    border-radius: 50px !important;
                    padding: 10px 30px 10px 30px !important;
                    font-weight: 600 !important; 
                  }

                  .navbar-toggler-icon{
                    background-image: none;
                    width: 2em;
                  }
                  
                  @media (max-width: 768px) {
                    .container{
                      --bs-gutter-x: 0rem !important; 
                    }
                  
                    .usernav {
                      padding: 0.8rem !important;
                    }
                  
                    .navbar-toggler{
                      font-size: 15px !important;
                      outline: none !important;
                      color: #EB5885;
                      padding-left: 0.45rem;
                      padding-right: 0.45rem;
                    }
                  
                    .user-nav-img img{
                      width: 30vw;
                    }
                  }
                  
                `}</style>
      <nav className="navbar navbar-expand-lg navbar-light usernav">
        <div className="container usercontainer">
          <a className="navbar-brand user-nav-img" href="#">
            <img src={logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={menuActive}
            aria-label="Toggle navigation"
          >
            <img className="navbar-toggler-icon" src={togal_icon} alt="togal_icon" />
          </button>
          <div className={`collapse navbar-collapse justify-content-end ${menuActive ? 'show' : ''}`} onClick={closeSubmenu}>
            <ul className="navbar-nav">

              <li className="nav-item">
                <Link className="nav-link mx-3" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-3" href="#">
                  Top Teachers
                </a>
              </li>
              <li className="nav-item">
                <Link to="/blog-home" className="nav-link mx-3">
                  Blog
                </Link>
              </li>
              <li className={`nav-item dropdown ${submenuActive ? 'show' : ''}`}>
                <a
                  className="nav-link mx-3 dropdown-toggle"
                  href="#"
                  role="button"
                  onClick={toggleItem}
                  onKeyPress={toggleItem}
                >
                  Contact
                </a>
                <div className={`dropdown-menu ${submenuActive ? 'show' : ''}`}>
                  <a className="dropdown-item" href="tel:+91 90993 06894">
                    Call
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://api.whatsapp.com/send/?phone=%2B919099306894&text=%E0%AA%B9%E0%AB%81%E0%AA%82+%E0%AA%86+%E0%AA%B5%E0%AA%BF%E0%AA%B6%E0%AB%87+%E0%AA%AA%E0%AB%82%E0%AA%9B%E0%AA%B5%E0%AA%BE+%E0%AA%AE%E0%AA%BE%E0%AA%82%E0%AA%97%E0%AB%81+%E0%AA%9B%E0%AB%81%E0%AA%82..&type=phone_number&app_absent=0"
                  >
                    WhatsApp
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link user-nav-link"
                  href="https://play.google.com/store/apps/details?id=com.nib.edutor&pli=1"
                >
                  Install
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;