import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/dtm/count`)
      .then((response) => response.json())
      .then((data) => setCount(data.count))
      .catch((error) => console.error("Error fetching user count:", error));
  }, []);

  //Count of Total Content
  const [content, setUsers] = useState([]);
  const [render, setRender] = useState(false);
  const [totalTeamInfoCount, setTotalTeamInfoCount] = useState(0); // Initialize the state for total count

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getteam`);
      setUsers(res.data);
    };
    fetchData();
  }, [render]);

  useEffect(() => {
    let count = 0;
    content.forEach((entry) => {
      if (Array.isArray(entry.addcontent)) {
        count += entry.addcontent.length;
      }
    });
    setTotalTeamInfoCount(count);
  }, [content]);

  //NMMS
  const [nmmsQuiz, setnmmsQuiz] = useState([]);
  const [nmmsTeacher, setnmmsTeacher] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/getteacher`);
      setnmmsQuiz(res.data);
      setnmmsTeacher(res.data.length);
    };
    fetchData();
  }, [render]);

  const [totalnmmsQuiz, setTotalnmmsQuiz] = useState(0);

  useEffect(() => {
    let count = 0;
    nmmsQuiz.forEach((entry) => {
      if (Array.isArray(entry.addquiz)) {
        count += entry.addquiz.length;
      }
    });
    setTotalnmmsQuiz(count);
  }, [nmmsQuiz]);

  //Gyan-Sadhana
  const [GyanSadhanaQuiz, setGyanSadhanaQuiz] = useState([]);
  const [GyanSadhanaTeacher, setGyanSadhanaTeacher] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/gyansadhana/getteacher`);
      setGyanSadhanaQuiz(res.data);
      setGyanSadhanaTeacher(res.data.length);
    };
    fetchData();
  }, [render]);

  const [totalGyanSadhanaQuiz, setTotalGyanSadhanaQuiz] = useState(0);

  useEffect(() => {
    let count = 0;
    GyanSadhanaQuiz.forEach((entry) => {
      if (Array.isArray(entry.addquiz)) {
        count += entry.addquiz.length;
      }
    });
    setTotalGyanSadhanaQuiz(count);
  }, [GyanSadhanaQuiz]);

  //Navoday
  const [NavodayQuiz, setNavodayQuiz] = useState([]);
  const [NavodayTeacher, setNavodayTeacher] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/navoday/getteacher`);
      setNavodayQuiz(res.data);
      setNavodayTeacher(res.data.length);
    };
    fetchData();
  }, [render]);

  const [totalNavodayQuiz, setTotalNavodayQuiz] = useState(0);

  useEffect(() => {
    let count = 0;
    NavodayQuiz.forEach((entry) => {
      if (Array.isArray(entry.addquiz)) {
        count += entry.addquiz.length;
      }
    });
    setTotalNavodayQuiz(count);
  }, [NavodayQuiz]);
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section dashboard">
          {/* Left side columns */}
          <div className="row">
            {/* Sales Card */}
            <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">DTM Total Team</h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-person-lines-fill" />
                    </div>
                    <div className="ps-3">
                      <h6>{count}</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <h5 class="card-title">Total Content</h5>
                    <div class="d-flex align-items-center">
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i class="bi bi-card-checklist"></i>
                      </div>
                      <div class="ps-3">
                        <h6>{totalTeamInfoCount}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">NMMS Total Teacher</h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-circle"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{nmmsTeacher}</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <h5 class="card-title">Total Quiz</h5>
                    <div class="d-flex align-items-center">
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i class="bi bi-file-check"></i>{" "}
                      </div>
                      <div class="ps-3">
                        <h6>{totalnmmsQuiz}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            &nbsp; &nbsp; &nbsp; &nbsp;
            <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">Gyan Sadhana Total Teacher</h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-check"/>
                    </div>
                    <div className="ps-3">
                      <h6>{GyanSadhanaTeacher}</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <h5 class="card-title">Total Quiz</h5>
                    <div class="d-flex align-items-center">
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-check-all"></i>
                      </div>
                      <div class="ps-3">
                        <h6>{totalGyanSadhanaQuiz}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            &nbsp; &nbsp; &nbsp; &nbsp;
            <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">Navoday Total Teacher</h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i class="bi bi-person-check"/>
                    </div>
                    <div className="ps-3">
                      <h6>{NavodayTeacher}</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <h5 class="card-title">Total Quiz</h5>
                    <div class="d-flex align-items-center">
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-check-all"></i>
                      </div>
                      <div class="ps-3">
                        <h6>{totalNavodayQuiz}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </section>
      </main>
      {/* End #main */}
    </>
  );
};

export default Dashboard;
