import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import icon1 from "../../assets/img/quizicon1.png";
import icon2 from "../../assets/img/quizicon2.png";
import icon3 from "../../assets/img/quizicon3.png";
import icon4 from "../../assets/img/quizicon4.png";
import icon5 from "../../assets/img/quizicon5.png";
import icon6 from "../../assets/img/quizicon6.png";
import icon7 from "../../assets/img/quizicon7.png";
import icon8 from "../../assets/img/quizicon8.png";
import icon9 from "../../assets/img/quizicon9.png";
import icon10 from "../../assets/img/quizicon10.png";


function Nmmsteacher() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  //Get Single Teacher Profile
  const { id } = useParams();
  const [team, setUsers] = useState(null);
  const [render, setRender] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const res = await axios.get(`${API_BASE_URL}/api/single/teacher/${id}`);
      setUsers(res.data);
    };
    fetchData();
  }, [render]);

  //Display Get Quiz
  const [teaminfo, setarr] = useState([]);
  const [getuserdata, setuserdata] = useState([]);

  const GetTeamInfo = async () => {
    const res = await fetch(`${API_BASE_URL}/api/getquiz/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (!data) {
      alert("Something Went Wrong, Please Try Again Later!");
    } else {
      setarr(data.addquiz);
    }
  };
  useEffect(() => {
    GetTeamInfo();
  }, []);

  //Icon Dyanamic Code
  const icons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9, icon10];
  const [isOpen, setIsOpen] = useState(false);

  //NMMS Year 2023 And 2024
  const [selectedYear, setSelectedYear] = useState(2023);

  const handleButtonClick = (year) => {
    setSelectedYear(year);
    const btn2023 = document.getElementById('btn2023');
    const btn2024 = document.getElementById('btn2024');

    if (year === '2023') {
      btn2023.classList.add('active');
      btn2024.classList.remove('active');
    } else {
      btn2023.classList.remove('active');
      btn2024.classList.add('active');
    }
  };

  useEffect(() => {
    setSelectedYear('2023');
  }, []); 
  
  const filteredData = selectedYear ? teaminfo.filter(item => item.year === selectedYear) : teaminfo;

  useEffect(() => {
    if (team) {
      document.title = `${team.teachername} - NMMS પરીક્ષા મિશન`;
    }
  }, [team]);

  return (
    <>
      <Header />
      {team && (
        <div id="nmms_container_1" className="container sub_container_teacher">
          <div className="raw">
            <div className="col mt-5">
              <img
                src={`https://edutorappweb-bucket.s3.ap-south-1.amazonaws.com/NMMS/TeacherProfile/${team.teacherimg}`}
                alt=""
              />
            </div>
            <div className="col mt-4">
              <h1 className="fw-bold">{team.teachername}</h1>
              <p className="h4 mt-3">{team.schoolname}</p>
            </div>
          </div>
        </div>
      )}

      <div id="nmms_quiz_year" className="container text-center">
        <button id="btn2024" className="h3 mx-5 my-5 fw-bold" style={{ color: "#2577e0" }} onClick={() => handleButtonClick('2024')}>NMMS ક્વિઝ 2024</button>

        <button id="btn2023" className="h3 mx-5 my-5 fw-bold active" style={{ color: "#2577e0" }} onClick={() => handleButtonClick('2023')} active>NMMS ક્વિઝ 2023</button>
        
        <div id="underline" className="underline"></div>
      </div>



      {filteredData
        .slice(0)
        .map((ele, i) => {
          const iconIndex = i % icons.length;
          const icon = icons[iconIndex];
          return (
            <div className="container sub_container_teacher">
              <div className="m-top">
                <div>
                  <Link className="slide-top" to={`${ele.link}`}>
                    <div className="bg-light px-0 Quiz_profile">
                      <div className="Quiz_detail">
                        <h3 className="mb-2 fw-bold">{ele.tittle}</h3>
                        <p className="All_paragraph mb-0 mt-2">
                          {ele.subject} - {ele.year}{" "}
                        </p>
                      </div>
                      <div className="Quiz_img">
                        <img src={icon} alt="" />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

      <Footer />
    </>
  );
}

export default Nmmsteacher;
