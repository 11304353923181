import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const CreateTeacherProfile = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [teacherimg, setFile] = useState("");
  const [teachername, setTeacherName] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const [subject, setSubject] = useState("");

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
  };

  //validuser
  const UserdValid = async () => {
    let token = localStorage.getItem("usersdatatoken");

    const res = await fetch(`${API_BASE_URL}/api/v1/validuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();

    if (data.status == 401 || !data) {
      navigate('/admin/login');
    } else {
    }
  };

  useEffect(() => {
    UserdValid()
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("teachername", teachername);
    formData.append("teacherimg", teacherimg);
    formData.append("schoolname", schoolname);
    formData.append("subject", subject);

    try {
      await axios.post(`${API_BASE_URL}/api/createteacher`, formData);
      alert("Teacher data saved!");
      navigate("/nmms-admin");
    } catch (error) {
      alert("Something Went Wrong, Please Try Again Later!");
      console.log("Error creating teacher profile:", error.message);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create Teacher Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">NMMS</li>
              <li className="breadcrumb-item active">Add Teacher Profile</li>
            </ol>
          </nav>
        </div>

        <div className="col-lg">
          <div className="card">
            <div className="card-body mt-3">
              {/* Vertical Form */}
              <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher name:
                  </label>
                  <input
                    name="teachername"
                    value={teachername}
                    onChange={(e) => setTeacherName(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12 mt-2 mb-2">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter School name:
                  </label>
                  <input
                    name="schoolname"
                    value={schoolname}
                    onChange={(e) => setSchoolName(e.target.value)}
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="School name"
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Subject name:
                  </label>
                  <input
                    name="subject"
                    defaultValue={`ગણિત, માનસિક ક્ષમતા કસોટી, વિજ્ઞાન, સામાજીક વિજ્ઞાન ${subject}`}
                    type="text"
                    onChange={(e) => setSubject(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="inputNanme4" className="form-label">
                    Enter Teacher Photo:
                  </label>
                  <input
                    name="teacherimg"
                    type="file"
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={setimgfile}
                    required
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Submit
                  </button>
                </div>
              </form>
              {/* Vertical Form */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateTeacherProfile;
