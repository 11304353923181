import Routes from "./Allroutes";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes />
      <Analytics />
    </>
  );
}

export default App;
